<template>
    <div></div>
</template>

<script>
import Pusher from "pusher-js";
import { mapGetters } from 'vuex';

export default {

    name: "event-pusher",
    beforeMount() {
        this.subscribe();
    },

    computed: {
        ...mapGetters(["user"])
    },

    methods: {
        /** Init Pusher **/
        subscribe() {
            let pusher = new Pusher(process.env.VUE_APP_YOUR_PUSHER_APP_KEY, {
                cluster: process.env.VUE_APP_YOUR_CLUSTER,
            });


            // design upload channel

            let channel = pusher.subscribe("products");

            channel.bind("pusher:subscription_succeeded", () => {
                console.log("subscribed to channel");
            });

            channel.bind("pusher:subscription_error", (error) => {
                console.log(error);
            });

            pusher.bind(
                `App\\Events\\ProductPublishingStatusUpdated`,
                (data) => {
                    if (data) {

                        
                        this.$store.dispatch("changeDesignStatus", [
                            data.design_id,
                            data.status
                        ]);

                        if(data.status.ps_id < 2 || data.status.ps_id > 3 ){
                            this.$store.dispatch("getDesignsList",[12, 1])
                        }
                    }
                }
            );


            // user notification channel
            let notification_channel = pusher.subscribe(`notifications_${this.user.user_id}`)

            notification_channel.bind("pusher:subscription_succeeded", () => {
                console.log("subscribed to notification channel");
            });

            notification_channel.bind("pusher:subscription_error", (error) => {
                console.log(error);
            });

            pusher.bind(
                `App\\Events\\SendNotificationEventToUser`,
                () => {
                    this.$store.dispatch('getUser')
                }
            );


            
        },
    },
};
</script>

<style></style>
