import axios from "axios";

var baseUrl = process.env.VUE_APP_BASE_URL;

const viewOrder = {
    state: {
        singleOrderToView: null,
    },
    mutations: {
        setOrderToview: (state, order) => (state.singleOrderToView = order),
    },

    actions: {
        getOrder({ commit, dispatch }, orderId) {
            
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/orders/${orderId}`)
                    .then((response) => {
                        commit("setOrderToview", response.data.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });

        },

        updateRecipientInformation({ dispatch }, [orderId, payload]) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/orders/${orderId}/update`, payload)
                    .then((response) => {
                        dispatch("getOrder", orderId);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }

                        if (error.response.status === 403) {
                            dispatch("setSnackbar", {
                                active: true,
                                color: "bg-red-500",
                                text: "Order information cannot be edited! Order is sent to production! ",
                                timeout: 4500,
                            });
                        }
                        reject(error);
                    });
            });
        },

        resetOrderToView({ commit }) {
            commit("setOrderToview", null);
        },
    },

    getters: {
        orderToView: (state) => state.singleOrderToView,
    },
};

export default viewOrder;
