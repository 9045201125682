const canvasConfigure = {
    state: {
        activeVariantTableItem: null, //Natural Wrap, Mirror , Pixel Extend
    },
    mutations:{
        setActiveVariantTableItem: (state, name)=>{
            state.activeVariantTableItem = name
        }
    },
    actions: {
        setActiveVariantTableItem({commit}, name){
            commit('setActiveVariantTableItem', name)
        }
    },

    getters:{
        activeVariantTableItem: (state) => state.activeVariantTableItem
    }
};

export default canvasConfigure;
