const loginModal = {
    state: {
        //display modal
        loginModalActive: false,
        //form displayed in modal
        activeAuthModalForm: "login-form",
    },

    mutations: {
        setLoginModal: (state, value) => (state.loginModalActive = value),

        resetLoginForm(state) {
            state.activeAuthModalForm = "login-form";
        },
        setLoginForm(state, form) {
            state.activeAuthModalForm = form;
        },
    },

    actions: {
        setLoginModal({ commit }, value) {
            commit("setLoginModal", value);
        },
        resetLoginForm({ commit }) {
            commit("resetLoginForm");
        },

        setLoginForm({ commit }, form) {
            commit("setLoginForm", form);
        },
    },

    getters: {
        loginModal: (state) => state.loginModalActive,
        activeAuthModalForm: (state) => state.activeAuthModalForm,
    },
};

export default loginModal;
