import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const addCustomProductToCart = {
    state: {},

    mutations: {},

    actions: {
        addCustomProductToCart({ dispatch, commit }, productID) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/products/${productID}/add-to-cart`)

                    .then((response) => {

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Product added to basket!",
                            timeout: 2500,
                        });

                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {},
};
export default addCustomProductToCart;
