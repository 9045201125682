import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const resetPassword = {
    state: {
        resetCheckResponse: null
    },

    mutations: {

        setResetCheckResponse: (state, response)=> state.resetCheckResponse = response 
    },

    actions: {
        sendResetPasswordLink({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/request_password_reset", {
                        email: credentials.email,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        checkResetToken({ commit }, token) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/check_password_reset/"+ token)
                    .then((response) => {
                        commit('setResetCheckResponse', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        resetPassword({ dispatch, commit }, [password, token]) {
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Updating password...",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/update_password_reset/"+ token, password)
                    .then((response) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Password successfully updated!",
                            timeout: 2500,
                        })
                        this.$route.push({name: 'product list'})
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {

        resetCheckResponse: state=> state.resetCheckResponse
    },
};

export default resetPassword;
