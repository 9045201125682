var helpers = require("@/common/helpers/variants.js");

const qualityCheck = {
    state: {
        dpi: [
            { value: 0, name: "poor" },
            { value: 200, name: "medium" },
            { value: 300, name: "good" },
        ],
        poorQualityVariants: null,
        currentVariantQuality: null,
    },

    mutations: {
        setVariantQuality: (state, quality) =>
            (state.currentVariantQuality = quality),

            sortPoorQualityVariants: (state, variants) => {
                state.poorQualityVariants = variants
            }    
    },

    actions: {
        showVariantQuality(
            { commit },
            [
                widthMM,
                heightMM,
                originalWidth,
                canvasWidth,
                workspaceWidth,
                workspaceHeight,
            ]
        ) {
            let variantQuality = helpers.getQualityResult([
                widthMM,
                heightMM,
                originalWidth,
                canvasWidth,
                workspaceWidth,
                workspaceHeight,
            ]);

            commit("setVariantQuality", variantQuality);
        },

        sortPoorQualityVariants({commit}){
            let variants = []
            this.getters.variantList.forEach(variant => {
                let widthMM = variant.cv_width
                let heightMM = variant.cv_height
                let originalWidth = this.getters.currentDesign.client_design.cd_width
                let canvasWidth = variant.cv_canvas_width
                let workspaceWidth = variant.cv_crop_w ? variant.cv_crop_w : (variant.cv_width * 3.7795275591)
                let workspaceHeight = variant.cv_crop_h ? variant.cv_crop_h : (variant.cv_height * 3.7795275591)

                let quality = helpers.getQualityResult([
                    widthMM,
                    heightMM,
                    originalWidth,
                    canvasWidth,
                    workspaceWidth,
                    workspaceHeight,
                ]);

                if (quality === "poor" || quality === "medium"){
                    variants.push(variant.cv_id)
                }
            })
            
            commit('sortPoorQualityVariants', variants)
        }
    },

    getters: {
        currentVariantQuality: (state) => state.currentVariantQuality,
        poorQualityVariants: (state) => state.poorQualityVariants,
        dpiValues: (state) => state.dpi,
    },
};

export default qualityCheck;
