import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

import availableIntegrations from "./availableIntegrations";
import resetIntegration from './resetIntegration';

const settings = {
    state: {
        settingsData: null,
        activeSettingsTab: "account",
    },

    mutations: {
        setActiveSettingsTab(state, value) {
            state.activeSettingsTab = value;
        },

        setUserSettings: (state, settings) => (state.settingsData = settings),

        resetSettings(state) {
            (state.settingsData = null), (state.activeSettingsTab = "account");
        },
    },

    actions: {
        setActiveSettingsTab({ commit }, value) {
            commit("setActiveSettingsTab", value);
        },

        getUserSettings({ commit, dispatch }) {
            let userId = this.getters.user.user_id;
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/users/${userId}`)
                    .then((response) => {
                        commit("setUserSettings", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        saveProfileSettings({ dispatch }, settings) {
            let userId = this.getters.user.user_id;

            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Updating settings",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/users/${userId}/update-settings`, settings)
                    .then((response) => {
                        dispatch("getUser");
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Settings successfully updated!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        reject(error);
                    });
            });
        },

        saveCompanySettings({ dispatch }, settings) {
            let userId = this.getters.user.user_id;
            

            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Updating settings",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/users/${userId}/update-company-details`, settings)
                    .then((response) => {
                        dispatch("getUser");
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Settings successfully updated!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        reject(error);
                    });
            });
        },

        saveIntegrationSettings({ dispatch }, integrationValues) {
            let clientId = this.getters.user.user_client.client_id;
            let settings = {
                client_store_url: this.getters.settingsData.user_client
                    .client_integration.client_store_url,
                client_st_id: this.getters.settingsData?.user_client
                    ?.client_integration?.client_store_type?.st_id || null
            };
            //check if there is integration values and send them to server
            if (integrationValues) {
                settings = Object.assign(integrationValues, settings);
            }

            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Updating settings",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/clients/${clientId}`, settings)
                    .then((response) => {
                        dispatch("getUser");
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Settings successfully updated!",
                            timeout: 3500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        reject(error);
                    });
            });
        },

        changeUserPassword({ dispatch }, payload) {
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Updating password",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/change-password`, payload)
                    .then((response) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Password successfully updated!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        reject(error);
                    });
            });
        },

        uploadFiles({ commit, dispatch }, [file, type]){
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Uploading design",
                color: "bg-accent",
            });

            var formData = new FormData();

            formData.append("file", file);
            formData.append("file_type", type);

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/client/files/upload`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })

                    .then((response) => {
                        dispatch("getUserSettings");
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Upload Successful!",
                            timeout: 2500,
                        });
                        file = null;
                        resolve(response);
                    })
                    .catch((error) => {
                        file = null;
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem uploading file!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },
        deleteFiles({ commit, dispatch }, id){
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Uploading design",
                color: "bg-accent",
            });

            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + `/api/client/files/${id}/delete`)

                    .then((response) => {
                        dispatch("getUserSettings");
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Delete Successful!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem deleting file!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        }
    },

    getters: {
        activeSettingsTab: (state) => state.activeSettingsTab,
        settingsData: (state) => state.settingsData,
    },

    modules: {
        availableIntegrations,
        resetIntegration
    },
};

export default settings;
