const photoCalendar = {
    actions: {
        changePhotoCalendarPrices(
            { commit },
            [variant, newMarkup, newRetailPrice]
        ) {
            let design = this.getters.currentDesign;

            if (design) {
                design.client_product.client_product_attributes.prices[
                    variant
                ].cv_markup = newMarkup;
                design.client_product.client_product_attributes.prices[
                    variant
                ].cv_retail_price = newRetailPrice;
            }

            commit("setCurrentDesign", design);
        },

        changePhotoCalendarStatus({ commit }, [variant, value]) {
            let design = this.getters.currentDesign;

            if (value) {
                design.client_product.client_product_attributes.size.push(
                    variant
                );
            }

            

            if (!value) {
                let index = design.client_product.client_product_attributes.size.findIndex(
                    (size) => size === variant
                );
                if (
                    index > -1 &&
                    design.client_product.client_product_attributes.size
                        .length > 1
                ) {
                    design.client_product.client_product_attributes.size.splice(
                        index,
                        1
                    );
                }
            }

            commit("setCurrentDesign", design);
        },
    },
};

export default photoCalendar;
