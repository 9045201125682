import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const basketContent = {
    state: {
        basketItemList: null,
    },

    mutations: {

        getCart(state, cart) {
            state.basketItemList = cart;
        },

        clearCart:(state)=> state.basketItemList = null
    },

    actions: {
        addDesignToCart({ dispatch, commit }, product) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/cart/designs/add-to-cart", product)
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Design added to basket!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        addDesignAndProductToCart({ dispatch, commit }, variantID) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/products/variants/${variantID}/add-to-cart`)
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Design added to basket!",
                            timeout: 2500,
                        });

                        resolve(response);

                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        removeDesignFromCart({ commit, dispatch }, designId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/cart/designs/remove-from-cart", {
                        data: { cd_id: designId },
                    })
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Design sucessfuly removed from basket!",
                            timeout: 2500,
                        });
                        dispatch("getCart");
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        getCart({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/cart/create")
                    .then((response) => {
                        commit("getCart", response.data);
                        dispatch('resetSpecialLoading')
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        setCart({ dispatch, commit }, [designId, cartItems]) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/cart/items/add-to-cart", {
                        cart_designs: [
                            {
                                cd_id: designId,
                                cart_items: cartItems
                            }
                        ],
                    })
                    .then((response) => {
                        dispatch('getCart')
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        removeProductFromDesign({ commit, dispatch }, ci_id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/cart/items/remove-from-cart", {
                        data: { ci_id: ci_id },
                    })
                    .then((response) => {
                        dispatch("getCart");
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        updateBasketVariant({commit, dispatch}, [cartItemId, body]){
            dispatch('setSpecialLoading', cartItemId)
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/cart/items/"+ cartItemId , body)
                    .then((response) => {
                        dispatch('getCart')
                        
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        updateBasketMaterials({commit, dispatch}, body){
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/cart", body)
                    .then((response) => {
                        dispatch("getCartInformation");
                        
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        clearCart({commit}){
            commit('clearCart')
        }


    },

    getters: {
        basketItemList: (state) => state.basketItemList,
    },
};

export default basketContent;
