<template>
    <div
        @drop="onDrop($event)"
        @dragenter.prevent
        @dragover.prevent
        @click="registerClick"
        id="app"
        class=""
    >       
        <!-- layout component - all layouts imported through this component -->
        <App-Layout>
            <router-view />
        </App-Layout>
    </div>
    <!-- file with alll modals -->
    <Modals />
    <event-pusher v-if="user" />
</template>

<script>
import { mapGetters } from "vuex";
import EventPusher from "./common/components/eventPusher/event-pusher.vue";
import Modals from "./common/components/modals/modals.vue";

export default {
    components: { EventPusher, Modals },
    naem: "app",
    computed: {
        ...mapGetters(["authenticated", "user"]),
    },

    created(){
        // localStorage.removeItem('announcment_read')
            this.$store.dispatch("setAnnouncment", {
                                active: true,
                                color: "bg-red-400",
                                text: "Please visit our About page for an update on our price change starting 1st February 2023."
                            })
          

    },

    methods: {
        // sending event target to manage menus
        registerClick(event) {
            this.emitter.emit("clickTarget", event);
        },
        //deactivate drag and drop drop area
        onDrop() {
            this.$store.dispatch("dragDeactivate");
        },
    },
};
</script>

<style>
#app {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
