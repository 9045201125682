

const orderRefundModal = {
    state: {
        orderRefundModal: false,
        returnItemList: [],
    },

    mutations: {
        setOrderRefundModal: (state, dialogState) =>
            (state.orderRefundModal = dialogState),

        setReturnItemList: (state, items)=> state.returnItemList = items
    },

    actions: {
        setOrderRefundModal({ commit }, state) {
            commit("setOrderRefundModal", state);
        },

        setReturnItemList({ commit }, items) {
            commit("setReturnItemList", items);
        },
    },

    getters: {
        orderRefundModal: (state) => state.orderRefundModal,
        returnItemList: (state) => state.returnItemList,
    },
};

export default orderRefundModal;
