import axios from "axios";
import updateProduct from "./updateProduct";

var baseUrl = process.env.VUE_APP_BASE_URL;

const productGallery = {
    state: {
        availableProductList: null,
        productsToBeEnabled: [],
        businessCardsSelectedDesign: null
    },

    mutations: {
        setAvailableProductList: (state, products) =>
            (state.availableProductList = products),

        changeStateOfProductFromList: (state, list) =>
            (state.availableProductList = list),

        addProductToPostQueue: (state, item) =>
            state.productsToBeEnabled.push(item),

        setProductsToBeEnabled: (state, list) =>
            (state.productsToBeEnabled = list),

        resetProductGallery(state) {
            (state.availableProductList = null),
                (state.productsToBeEnabled = []);
        },
        setBusinessCardsSelectedDesign: (state, id)=> state.businessCardsSelectedDesign = id
    },

    actions: {
        getAvailableProductList({ commit, dispatch }, designID) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl + `/api/client/designs/${designID}/products`
                    )
                    .then((response) => {
                        commit("setAvailableProductList", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        changeStateOfProductFromList(
            { commit, state, dispatch },
            [productId, value]
        ) {
            let productList = state.availableProductList;
            productList.forEach((product) => {
                if (product.cp_id === productId) {
                    product.cp_published = !!value;
                    
                    if (value) {
                        // enable product
                        commit("addProductToPostQueue", +product.cp_id);
                    }
                    if (!value) {
                        // disable published
                        dispatch("removeProductToPostQueue", +product.cp_id);
                    }
                    


                }
            });
            
            commit("changeStateOfProductFromList", productList);
        },

        removeProductToPostQueue({ commit, state }, item) {
            let list = state.productsToBeEnabled;
            list = list.filter((productId) => productId !== item);
            commit("setProductsToBeEnabled", list);
        },

        removeAllProductsFromPostQueue({ commit }) {
            let list = [];
            commit("setProductsToBeEnabled", list);
        },
        setBusinessCardsSelectedDesign({commit}, id){
            commit('setBusinessCardsSelectedDesign', id)
        }
    },

    getters: {
        availableProductList: (state) => state.availableProductList,
        productsToBeEnabled: (state) => state.productsToBeEnabled,
        businessCardsSelectedDesign: (state)=> state.businessCardsSelectedDesign
    },

    modules: {
        updateProduct,
    },
};

export default productGallery;
