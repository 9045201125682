<template>
    <div class="flex-1 flex justify-center items-center">
        <StripeCheckout
            v-if="sessionId && publishableKey"
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="sessionId"
        />

        <button @click="submit()">
            test
        </button>
        <div>
            <svg
                v-if="!sessionId"
                class="animate-spin h-6"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30px"
                height="211px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style="margin-right:-2px;display:block;background-repeat-y:initial;background-repeat-x:initial"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="32"
                    stroke-width="8"
                    stroke="#000000"
                    stroke-dasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    stroke-linecap="round"
                ></circle>
            </svg>
        </div>
    </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";

export default {

    created(){
        setTimeout(() => {
            if(!this.sessionId){
                this.$store.dispatch('resetPayments').then(()=>{
                    this.$store.dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: 'There has been problem with payment. Please contact support!',
                            timeout: 4000,
                        });
                })
            }
            
        }, 7000);
        this.$store.dispatch('removeOrderToPay')
        this.$store.dispatch('removeInvoiceToPay')
    },
    watch: {
        sessionId: function() {
            this.$nextTick(() => {
                this.submit();
            });
        },
    },

    computed: {
        ...mapGetters(["sessionId"]),
    },

    data() {
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
        return {
            loading: false,
        };
    },

    methods: {
        submit() {
            // You will be redirected to Stripe's secure checkout page
            // try {
            //     this.$refs.checkoutRef.redirectToCheckout();
            // } catch (error){
            //     console.error(error)
            // }

            setTimeout(() => {
                try {
                this.$refs.checkoutRef.redirectToCheckout();
            } catch (error){
                console.error(error)
            }
            
        }, 4000);
        },


        showStatus(e){
            console.log(e)
        }
    },

    name: "creditCardForm",
    components: {
        StripeCheckout,
    },
};
</script>

<style></style>
