<template>
    <div
        v-if="imageLinkModal.active"
        @click.self="cancel()"
        class="absolute inset-0 bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center"
    >
        <div class="bg-white w-96 h-72 flex flex-col rounded-3xl">
            <div
                class=" border-b text-white w-full h-1/4 rounded-t-3xl flex justify-center items-center"
            >
                <span class="text-accent font-medium"
                    >Replace print file</span
                >
            </div>
            <div
                class="flex-1 flex flex-col justify-center items-center text-center"
            >
                <!-- <span class="text-3xl text-gray-500 pb-2">Are you sure?</span> -->
                <span class=" text-gray-400 text-sm px-14"
                    >Print file will be changed to file you provide from link
                    below. Please enter valid url.</span
                >
                <form-input
                    width="300"
                    class="mt-3"
                    v-model:inputValue="url"
                />
                <span class="text-sm text-red-500 pt-1">
                    {{errors}}
                </span>
            </div>
            <div
                class=" bg-accent text-white w-full h-1/4 rounded-b-3xl flex justify-center"
            >
                <button
                    @click="submit"
                    class="text-2xl font-medium uppercase hover:bg-gray-100 hover:bg-opacity-30 px-8  focus:outline-none"
                >
                    save
                </button>
                <button
                    @click="cancel"
                    class="text-2xl font-medium uppercase hover:bg-gray-100 hover:bg-opacity-30 px-8  focus:outline-none"
                >
                    cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormInput from "../../formInput/formInput.vue";

export default {
    computed: {
        ...mapGetters(["imageLinkModal"]),
    },
    methods: {
        submit() {
            if(this.validateUrl(this.url)){
                this.errors = null
                 this.$store.dispatch("changeOrderItemImageLink", this.url).then(()=>{
                     this.$store.dispatch("setImageLinkModal", [false, null])
                 })
                 return
            }
            this.errors = 'Please provide valid URL'
        },
        validateUrl(string){
            // eslint-disable-next-line
            var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            return (res !== null)
        },
        cancel() {
            this.url = null
            this.errors = null
            this.$store.dispatch("setImageLinkModal", [false]);
        },
    },

    data(){
        return{
            url: null,
            errors: null
        }
    },
    name: "changeImageLinkModal",
    components: { FormInput },
};
</script>

<style></style>
