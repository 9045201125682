<template>
    <div class="flex justify-center pb-0 pt-4 w-full">
        <form
            class="flex justify-center items-center flex-col space-y-5 w-11/12 xl:w-6/12 lg:w-7/12  md:w-5/12 "
        >
            <input
                ref="email"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="email"
                v-model="input.email"
                placeholder="E-mail"
            />

            <input
                ref="name"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="name"
                v-model="input.name"
                placeholder="Full name"
            />

             <select
                v-if="availableCountryList"
                ref="selectCountry"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full appearance-none"
                name="country"
                v-model="input.country"
                :class="input.country === '' ? 'text-gray-400' : ''"
            >
                <option value="" selected disabled hidden
                    >Select country</option
                >
                <option
                    class="text-black"
                    v-for="country in availableCountryList.countries"
                    :key="country.country_id"
                    :value="country"
                    >{{ country.country_name }}</option
                >
            </select>
            <span class="text-center">
                By clicking Start creating, you agree to our
                <span
                    class="text-accent cursor-pointer"
                    @click="showTermsPopup()"
                    >terms</span
                >.
            </span>
        </form>
    </div>
    <div
        class="flex justify-center w-11/12 xl:w-6/12 lg:w-7/12  md:w-5/12  mx-auto"
        style=""
    >
        <div
            class="duration-500 flex w-full "
        >
            <div v-if="authErrorMessage || errors" class="text-red-600 flex">
                <span class="text-sm py-1">
                    {{ authErrorMessage }}
                    <template v-if="errors.length">
                        <div
                            v-for="error in errors"
                            :key="error"
                            class="flex text-xs"
                        >
                            <span class="mdi mdi-circle-small" />{{ error }}
                        </div>
                    </template>
                </span>
            </div>
        </div>
    </div>
    <div class="flex justify-center pt-5">
        <button
            class="block text-center text-white bg-accent p-3 duration-500 rounded-sm hover:bg-black w-52 focus:outline-none"
            type="submit"
            @click="registerGuestUser()"
        >
            Start creating
        </button>
    </div>
    <div class="uppercase text-xs py-2 tracking-wider text-accent mx-auto">
        <span @click="goToLogin()" class="cursor-pointer hover:text-black"
            >login</span
        >
    </div>

    <!-- pay popup -->
    <teleport to="#modals">
        <terms-and-conditions v-if="termsPopup" />
    </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import TermsAndConditions from "../../termsAndConditionsPopup/termsAndConditions.vue";
export default {
    components: { TermsAndConditions },

    created(){
        this.$store.dispatch("getCountryList");
    },
    computed: {
        ...mapGetters(["authErrorMessage", "termsPopup", "availableCountryList"]),
    },

    methods: {
        registerGuestUser() {
            this.checkForm();

            if (!this.errors) {
                var newUser = {
                    guest_user: true,
                    name: this.input.name,
                    email: this.input.email,
                    country_id: this.input.country.country_id
                };

                this.$store.dispatch("registerGuestUser", newUser).then(()=>{
                    this.$store.dispatch('setLoginForm', 'loading')
                })

                this.input = {
                    name: "",
                    email: "",
                    country: ""
                };
            }
        },

        goToLogin() {
            this.$store.dispatch("setLoginForm", "login-form");
            this.$router.push({ path: "/login" });
        },

        checkForm: function() {
            this.errors = [];

            if (this.input.name === "") {
                this.errors.push("Name field is required");
            }

            if (this.input.surname === "") {
                this.errors.push("Surname field is required");
            }

            if (!this.validEmail(this.input.email)) {
                this.errors.push("Valid email required.");
            }

            if (!this.errors.length) {
                this.errors = null;
                return true;
            }
        },

        validEmail: function(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        showTermsPopup() {
            this.$store.dispatch("setTermsPopup", true);
        },
    },

    data() {
        return {
            input: {
                name: "",
                country: "",
                email: "",
            },
            errors: null,
        };
    },
    name: "guestUserLoginForm",
};
</script>

<style></style>
