import axios from "axios";
import invoicesTable from "./InvoicesTable";
import invoicesOperations from './invoicesOperations';


var baseUrl = process.env.VUE_APP_BASE_URL;

const invoices = {

    actions: {
        getUserInvoices({ commit, dispatch }, page) {
            let query =
                (this.getters.invoicesqueryString
                    ? this.getters.invoicesqueryString
                    : "") +
                (this.getters.invoicesFilterString
                    ? this.getters.invoicesFilterString
                    : "");
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/invoices?page=${page}${query}`)
                    .then((response) => {
                        commit("setInvoices", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        downloadInvoicePDF({dispatch }, invoiceId) {

            dispatch("setSpecialLoading", invoiceId);
      
            return new Promise((resolve, reject) => {
              axios
                .get(baseUrl + "/api/invoices/" + invoiceId+ "/pdf" , {
                  responseType: "arraybuffer",
                })
                .then((response) => {
                  // It is necessary to create a new blob object with mime-type explicitly set
                  // otherwise only Chrome works like it should
                  var newBlob = new Blob([response.data], {
                    type: "application/pdf",
                  });
      
                  // IE doesn't allow using a blob object directly as link href
                  // instead it is necessary to use msSaveOrOpenBlob
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                  }
      
                  // For other browsers:
                  // Create a link pointing to the ObjectURL containing the blob.
                  const data = window.URL.createObjectURL(newBlob);
                  var link = document.createElement("a");
                  link.href = data;
                  link.download = `PP-invoice-${invoiceId}.pdf`;
                  link.click();
                  setTimeout(function() {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                  }, 100);
      
                  dispatch("resetSpecialLoading");
                  resolve(response);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    dispatch("destroyToken");
                    location.reload();
                  }
                  reject(error);
                });
            });
          },
    },

    modules: {
        invoicesTable,
        invoicesOperations
    },
};

export default invoices;
