import orderTable from "./orderTable";
import orderOperations from "./orderOperations";


import axios from "axios";

var baseUrl = process.env.VUE_APP_BASE_URL;
var helpers = require("@/common/helpers/orders.js");

const orders = {
    mutations: {},

    actions: {
        getUserOrders({ commit, dispatch }, page) {
            let query =
                (this.getters.ordersqueryString? this.getters.ordersqueryString : '' )+
                (this.getters.ordersFilterString?this.getters.ordersFilterString: '' )
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/orders?page=${page}${query}`)
                    .then((response) => {
                        commit("setOrders", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },


        changeOrderItemImageLink({ dispatch, getters }, url) {
            let orderID = getters.imageLinkModal.data.orderID
            let orderItemID = getters.imageLinkModal.data.itemID
            let newUrl = {
                oi_print_file_url: url
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/orders/${orderID}/items/${orderItemID}/add-print-file`, newUrl)
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Order file has been replaced successfully!",
                            timeout: 2500,
                        });
                        dispatch('getUserOrders')

                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
        
        deleteOrder({ dispatch }, orderId) {
            let page = this.getters.userOrders.meta.current_page
            let orders = {orders: [orderId]}
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + `/api/orders`, { data: orders })
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Order deleted successfully!",
                            timeout: 2500,
                        });
                        
                        dispatch('getUserOrders', page)
                        dispatch("getUser");
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
        
        deleteMultipleOrders({dispatch}) {
            let page = this.getters.userOrders.meta.current_page
            //extract id from selected orders
            let orders = helpers.getOrderIds(this.getters.selectedOrders)


            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + `/api/orders`, { data: orders })
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Orders deleted successfully!",
                            timeout: 2500,
                        });
                        dispatch('getUserOrders', page)
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },

    modules: {
        orderTable,
        orderOperations
    },
};

export default orders;
