exports.getOrderIds = (orders) => {
    let mappedIds = orders.map((order) => {
        return +order.order_id;
    });

    let result = {
        orders: mappedIds,
    };

    return result;
};
