
import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const activityLogAPI = {
    state:{
        orderActivityLog: [
            {
                date: '2012-01-25T13:51:50.417-07:00',
                status: {id: 1, name : 'new'},
            },
            {
                date: '2012-01-28T13:51:50.417-07:00',
                status: {id: 3, name : 'dispatched'},
            },
            {
                date: '2012-01-28T13:54:50.417-07:00',
                status: {id: 3, name : 'dispatched'},
            },
            {
                date: '2012-01-29T13:51:50.417-07:00',
                message: 'Hello there!'
            },
            {
                date: '2012-01-26T13:51:50.417-07:00',
                status: {id: 1, name : 'new'},
                note: 'Paid'
            },
            {
                date: '2012-01-27T13:51:50.417-07:00',
                status: {id: 2, name : 'in Process'},
            },
            
            {
                date: '2012-01-30T13:51:50.417-07:00',
                reply: 'Yo mazafaka'
            }
        ]
    },

    mutations:{
        setActivityLog: (state, logItems)=> state.orderActivityLog = logItems
    },

    actions:{
        setActivityLogMessage({ commit, dispatch }, [id, payload]) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/orders/${id}/add-comment`, payload)
                    .then((response) => {
                            dispatch(
                                "getOrder", id
                            );
                        resolve(response);
                    })
                    .catch((error) => {

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },
        uploadActivityLogFile({ commit, dispatch }, [id, payload]) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/orders/${id}/add-comment`, payload, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                            dispatch(
                                "getOrder", id
                            );
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "Upload failed!",
                            timeout: 2500,
                        });
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },
        
    },

    getters:{
        orderActivityLog: state=> state.orderActivityLog
    }
}

export default  activityLogAPI