import canvasConfigure from "./canvasConfigure";

const configure = {
    state: {
        proporties: {
            framedPosters: {
                frame: [],
            },
            canvas: {
                edges: [],
            },
            hangerPoster: {
                hanger: [],
            },
            deskCalendar:{
                starts_with: []
            }
        },
    },

    mutations: {
        //posters
        setFramedPosterProporty(state, value) {
           
            state.proporties.framedPosters.frame = value;
        },

        setFrameVariants: (state, variants) =>
            (state.proporties.framedPosters.frame = variants),
            
        //hanger
        setHangerPosterProporty(state, value) {
            state.proporties.hangerPoster.hanger = value;
        },

        setHangerVariants: (state, variants) =>
            (state.proporties.hangerPoster.hanger = variants),

        // canvas
        setCanvasWrapProporty: (state, value) => {
            state.proporties.canvas.edges = value;
        },

        setCanvasWrapVariants: (state, variants) =>
            (state.proporties.canvas.edges = variants),
        // desk calendar
        setDeskCalendarProporty: (state, value) => {
            state.proporties.deskCalendar.starts_with = value;
        },

        setDeskCalendarVariants: (state, variants) =>
            (state.proporties.deskCalendar.starts_with = variants),

        resetconfigure(state) {
            state.proporties = {
                framedPosters: {
                    frame: [],
                },
                canvas: {
                    edges: [],
                },
                hangerPoster: {
                    hanger: [],
                },
                deskCalendar:{
                    starts_with: []
                }
            };
        },
    },

    actions: {
        // posters
        setFramedPosterProporty({ commit }, value) {
            commit("setFramedPosterProporty", value);
        },

        setFrameVariants({ commit }, variants) {
            commit("setFrameVariants", variants);
        },

        //canvas
        setCanvasWrapProporty({ commit }, value) {
            commit("setCanvasWrapProporty", value);
        },

        setCanvasWrapVariants({ commit }, variants) {
            commit("setCanvasWrapVariants", variants);
        },
        // hangers
        setHangerPosterProporty({ commit }, value) {
            commit("setHangerPosterProporty", value);
        },

        setHangerVariants({ commit }, variants) {
            commit("setHangerVariants", variants);
        },
        // desk calendars
        setdeskCalendarProporty({ commit }, value) {
            commit("setdeskCalendarProporty", value);
        },

        setDeskCalendarVariants({ commit }, variants) {
            commit("setDeskCalendarVariants", variants);
        },
    },

    getters: {
        configureProporties: (state) => state.proporties,
    },

    modules: {
        canvasConfigure,
    },
};

export default configure;
