import axios from "axios";
import router from "../../../../router";
var baseUrl = process.env.VUE_APP_BASE_URL;

const designUpload = {
    actions: {
        uploadDesign({ commit, dispatch }, event) {
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Uploading design",
                color: "bg-accent",
            });

            let file = event.target.files[0];

            var formData = new FormData();

            formData.append("print_file", file);

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/client/designs`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })

                    .then((response) => {
                        let isProductsView = router.currentRoute.value.name ===
                        "product designs"

                        if (this.getters.designsPagination.last_page > 1) {
                            if (isProductsView) {
                                dispatch("getDesignsList", [30, this.getters.designsPagination.last_page]);
                            } else {
                                dispatch("getDesignsList", [12, this.getters.designsPagination.last_page]);
                            }
                        } else {
                            if (isProductsView) {
                                dispatch("getDesignsList", [30]);
                            } else {
                                dispatch("getDesignsList", [12]);
                            }
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Upload Successful!",
                            timeout: 2500,
                        });
                        file = null;
                        resolve(response);
                    })
                    .catch((error) => {
                        file = null;
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem uploading file!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },
    },
};

export default designUpload;
