


const dragAndDrop = {

state:{
    isDragged: false,
},

mutations:{
    dragActivate: state=> state.isDragged = true,
    dragDeactivate: state=> state.isDragged = false
},

actions: {
    dragActivate({commit}){
        commit('dragActivate')
    },

    dragDeactivate({commit}){
        commit('dragDeactivate')
    }
},


getters:{
    isDragged: state => state.isDragged
}

}

export default dragAndDrop