const hangerPoster = {

state:{
    hangerColor: 'black'
},

mutations:{
    setHangerColor:(state, color) => state.hangerColor = color
},

actions:{
    setHangerColor({commit}, color){
        commit('setHangerColor', color)
    }
},


getters:{
    hangerColor:(state)=> state.hangerColor
}

}
export default hangerPoster