


const announcments  = {

    state:{
        discountAnnouncment: true,
    },

    mutations: {
        closeAnnouncment:(state)=> state.discountAnnouncment = false
    },

    actions:{
        closeAnnouncment({commit}){
            commit('closeAnnouncment')
        }
    },


    getters:{
        discountAnnouncment: state => state.discountAnnouncment
    }


}

export default announcments