const announcment = {
    state: {
        options: {
            active: false,
            color: "",
            text: "",
            timeout: null
        },
    },

    mutations: {
        hideAnnouncment: (state) => (state.options.active = false),
        setAnnouncment: (state, options) => (state.options = options),
    },

    actions: {
        setAnnouncment({ commit }, options) {
            let exparation = new Date('February 1, 2023')
            let date = new Date()
            let announcment = localStorage.getItem('announcment_read') || null

            if(!announcment && date < exparation){
                commit("setAnnouncment", options);
                if(options.timeout){
                    setTimeout(()=>{
                        commit('hideAnnouncment')
                    }, options.timeout)
                }
            }
            
        },

        hideAnnouncment({ commit }) {
            localStorage.setItem('announcment_read', true)
            commit("hideAnnouncment");
        },
    },

    getters: {
        announcmentOptions: (state) => state.options,
    },
};

export default announcment;
