

const invoicesTable = {
    state: {
        invoicesQueryString: null,
        invoicesFilterString: null
    },

    mutations:{
        setInvoiceQueryString: (state, string) => state.invoicesQueryString = string,
        setInvoiceFilterString: (state, string) => state.invoicesFilterString = string,

    },

    actions:{
        setInvoicesQueryString({commit}, string){
            commit('setInvoiceQueryString', string)
        },

        setInvoicesFilterString({commit}, string){
            commit('setInvoiceFilterString', string)
        },
    },

    getters:{
        invoicesFilterString: state => state.invoicesFilterString,
        invoicesqueryString: state => state.invoicesQueryString
    }

}

export default invoicesTable 