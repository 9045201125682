import axios from "axios";
import router from "../../../../../router";

var baseUrl = process.env.VUE_APP_BASE_URL;

const guestUserLogin = {
    state: {},
    mutations: {},
    actions: {
        registerGuestUser({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/register", payload)

                    .then((response) => {
                        const token = response.data.access_token;
                        const currentRoute = router.currentRoute.value.name;
                        localStorage.setItem("access_token", token);

                        commit("resetErrors");
                        commit("setToken", token);
                        
                            dispatch("getUser").then(() => {
                                //if we are in /products/x/variants and values have been selected
                                if (
                                    currentRoute === "variants" &&
                                    this.getters.haveProductStepIDs
                                ) {
                                    //we go to next step
                                    dispatch("goToDesignsStep");
                                } else {
                                    //if we are in different directory, we go to home view
                                    router.push(this.getters.homeView);
                                }

                            });

                            dispatch("setSnackbar", {
                                active: true,
                                color: "bg-green-500",
                                text:
                                    "You are welcome to continue. If you wish to access full expierience, please register!",
                                timeout: 4000,
                            });

                        resolve(response);
                    })

                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },
    },
    getters: {},
};

export default guestUserLogin;
