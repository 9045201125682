

const fileUpload = {
    state: {
        imageFile: null,
    },

    mutations: {
        setFile(state, file) {
            state.imageFile = file;
        },
    },

    actions: {
        setFile({ commit }, file) {
            let imageBlob = URL.createObjectURL(file); 
            commit("setFile", imageBlob);

          
        },
    },

    getters: {
        imageFile: (state) => state.imageFile,
    },
};

export default fileUpload;
