import fileUpload from "./fileUpload";
import isLoading from "./isLoading";
import snackbar from "../../common/components/snackbars/snackbar";
import basicPopup from "../../common/components/modals/basicPopup/basicPopup";
import termsAndConditions from '../../common/components/modals/termsAndConditionsPopup/termsAndConditions';
import importDesignFromDesigns from '../../common/components/modals/addImagesToProductDialog/importDesignFromDesigns';
import dragAndDrop from './dragAndDrop';
import searchDesign from './searchDesign';
import loginModal from '../../common/components/modals/authPopup/store/authModal';
import announcments from "../../modules/navigation/store/announcments";
import orderRefundModal from '../../common/components/modals/orderRefundModal/orderRefundModal';
import refundAPI from '../../common/components/modals/orderRefundModal/refundAPI';








const utilities = {
    modules: {
        fileUpload,
        isLoading,
        snackbar,
        basicPopup,
        termsAndConditions,
        announcments,
        importDesignFromDesigns,
        dragAndDrop,
        searchDesign,
        loginModal,
        orderRefundModal,
        refundAPI,
    },
};

export default utilities;
