var baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";



const resetIntegration = {
    state: {
    },

    mutations:{
    },

    actions:{
        resetStoreIntegration({ dispatch }, clientId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/clients/${clientId}/reset-integration`)
                    .then((response) => {
                        dispatch('getUserSettings')
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {
    },
};

export default resetIntegration;
