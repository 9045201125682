const isLoading = {
    state: {
        isLoading: false,
        specialLoading: null
    },

    mutations: {
        setLoading: (state) => (state.isLoading = true),
        resetLoading: (state) => (state.isLoading = false),

        setSpecialLoading: (state, loading) => (state.specialLoading = loading),
        resetSpecialLoading: (state) => (state.specialLoading = null),
    },

    actions: {
        setLoading({ commit }) {
            commit("setLoading");
        },
        resetLoading({ commit }) {
            commit("resetLoading");
        },

        setSpecialLoading({ commit }, loading) {
            commit("setSpecialLoading", loading);
        },
        resetSpecialLoading({ commit }) {
            commit("resetSpecialLoading");
        },
    },
    getters: {
        isLoading: (state) => state.isLoading,
        isSpecialLoading: (state) => state.specialLoading,
    },
};

export default isLoading;
