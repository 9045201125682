const snackbar = {
    state: {
        options: {
            active: false,
            color: "",
            text: "",
            timeout: null
        },
    },

    mutations: {
        hideSnackbar: (state) => (state.options.active = false),
        setSnackbar: (state, options) => (state.options = options),
    },

    actions: {
        setSnackbar({ commit }, options) {
            commit("setSnackbar", options);
            if(options.timeout){
                setTimeout(()=>{
                    commit('hideSnackbar')
                }, options.timeout)
            }
        },

        hideSnackbar({ commit }) {
            commit("hideSnackbar");
        },
    },

    getters: {
        snackbarOptions: (state) => state.options,
    },
};

export default snackbar;
