import axios from "axios";
import register from "./register";
import router from '../../../../../router';
import guestUserLogin from './guestUserLogin';

var baseUrl = process.env.VUE_APP_BASE_URL;

const auth = {
    modules: {
        register,guestUserLogin
    },
    state: {
        token: localStorage.getItem("access_token") || null,
        user: null,
        authErrorStatus: false,
        authErrorMessage: null,
        errors: {
            email: null,
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;
        },
        destroyToken(state) {
            state.token = null;
            state.user = null;
        },
        setAuthErrorMessage(state, message) {
            state.authErrorMessage = message;
            state.authErrorStatus = true;
        },
        setErrorMessages(state, errors) {
            state.errors = errors;
        },
        clearErrors(state) {
            state.authErrorStatus = false;
            state.authErrorMessage = null;
            state.errors = {
                email: null,
            };
        },
    },
    actions: {
        setToken({commit}, token){
            commit('setToken', token)
        },
        getToken({ commit, dispatch }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/login", {
                        email: credentials.email,
                        password: credentials.password,
                    })
                    .then((response) => {
                        const token = response.data.access_token;
                        const currentRoute = router.currentRoute.value.name
                        localStorage.setItem("access_token", token);


                        commit("resetErrors");
                        commit("setToken", token);

                        dispatch("getUser").then((response)=> {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event': 'log_in',
                                'userId': response?.user_id || null,
                                'userEmail': response?.user_email || null 
                            });
                            //if we are in /products/x/variants and values have been selected
                            if (currentRoute === "variants" && this.getters.haveProductStepIDs) {
                                //we go to next step
                                dispatch("goToDesignsStep");
                            } else 
                            {   
                                //if we are in different directory, we go to home view
                                router.push(this.getters.homeView);
                                dispatch("getProducts")
                            }

                        })
                        
                        resolve(response);
                    })
                    .catch((error) => {

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        destroyToken({ state, commit }) {
            if (state.token) {
                return new Promise((resolve, reject) => {
                    axios
                        .get(baseUrl + "/api/logout")
                        .then((response) => {
                            
                            localStorage.removeItem("access_token");
                            
                            //remove header, because else all next requests will be with header and no token
                            axios.defaults.headers.common["Authorization"] = null

                            commit("destroyToken");
                            resolve(response);
                        })
                        
                        .catch((error) => {
                            localStorage.removeItem("access_token");
                            commit("destroyToken");
                            reject(error);
                        });
                });
            }
        },
        getUser({ commit, state, dispatch }) {
            if (state.token == null) {
                return;
            }

            axios.defaults.headers.common["Authorization"] =
                "Bearer " + state.token;

            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/user")
                    .then((response) => {
                        commit("setUser", response.data);


                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 403) {
                            dispatch("setSnackbar", {
                                active: true,
                                color: "bg-accent",
                                text: "Your account has not been verified! Please go to your registered email and verify your account!",
                                timeout: 10000,
                                resendEmail: true
                            });
                        }
                        
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        clearErrors({ commit }) {
            commit("clearErrors");
        },
    },
    getters: {
        authenticated(state) {
            return state.token !== null;
        },
        user(state) {
            return state.user;
        },
        userFunction(state) {
            return function() {
                return state.user;
            };
        },
        authErrorStatus(state) {
            return state.authErrorStatus;
        },
        authErrorMessage(state) {
            return state.authErrorMessage;
        },
        errors(state) {
            return state.errors;
        },
    },
};
export default auth
