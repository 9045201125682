<template>
    <div
        v-if="paymentsDialog"
        @click.self="closeDialog()"
        class="absolute inset-0 bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center"
    >
        <div
            class="bg-white w-96 flex flex-col rounded-3xl"
            style="min-height: 256px;"
        >
            <div
                class="relative border-b text-white w-full h-16 rounded-t-3xl flex justify-center items-center"
            >
                <span class="text-accent font-medium">Payment methods</span>
                <!-- <div
                    class="absolute right-4 text-white w-5 h-5 bg-red-400 hover:bg-red-500 rounded-full text-center text-sm cursor-pointer"
                >
                    <span class="mdi mdi-close"></span>
                </div> -->
            </div>

            <!-- content elements -->
            
            <component :is="paymentsActiveView" />
            
            <!-- dialog footer -->
            <div
                class=" bg-accent text-white w-full h-16 rounded-b-3xl flex justify-center"
            >
                <button
                    @click="closeDialog()"
                    class="text-2xl font-medium uppercase hover:bg-gray-100 hover:bg-opacity-30 px-8  focus:outline-none select-none"
                >
                    cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreditCardForm from './creditCardForm.vue';
import paymentMethods from "./paymentMethods.vue";
export default {

    created(){
        
    },

    computed: {
        ...mapGetters(["paymentsActiveView", "paymentsDialog"]),
    },

    methods: {
        closeDialog() {
            this.$store.dispatch("setPaymentsDialog", false);
            this.$store.dispatch('removeOrderToPay')
            this.$store.dispatch('removeInvoiceToPay')
        },

        
    },
    components: { paymentMethods, CreditCardForm },
    name: "PaymentDialog",
};
</script>

<style></style>
