<template>
  <div
        v-if="customSizeModal"
        class="absolute inset-0 max-h-screen bg-gray-200 z-50 bg-opacity-50 flex justify-center overflow-y-auto"
    >
        <div class="w-full md:w-[600px] drop-shadow-xl my-auto">
            <div class="bg-white">
                <div
                    class="relative border-b text-white w-full h-16 flex justify-center items-center"
                >
                    <span class="text-gray-800 font-medium"
                        >Custom print size</span
                    >
                </div>
                <!-- content elements -->
                <div class="p-7">
                    

                    <div class="flex flex-wrap gap-2">
                        <span class="text-md w-full">Units*: </span>
                        <input  name="optionA" v-model="units" value="imperial" type="radio">
                        <label for="optionA">Inches</label>
                        <input  name="optionB" v-model="units" value="metric" type="radio">
                        <label for="optionA">Centimeters</label>

                    </div>
                    <div class="py-1">
                        <span class="text-sm text-accent">Note: Maximum dimensions is {{units === 'metric'? '100 x 140 cm' : '39 x 55 inch'}}</span>
                        
                    </div>
                    
                    <div class="flex gap-x-4 items-center pt-4">
                        <div class="flex flex-col gap-y-1">
                            <span>Width*:</span>
                            <input v-model="size.width" type="number" :min="1"  class=" text-right p-1 border border-gray-500 w-[250px] outline-none rounded focus:ring-1 ring-accent"
                            :class="[size.width &&  validate(size.width) && 'ring-1 ring-red-600 border-none' ]">
                            <span class="text-xs text-accent text-right">{{units === 'metric'? 'from 20 cm' : 'from 8 inch'}}</span>

                        </div>
                        <div class="flex flex-col gap-y-1">
                            <span>Height*:</span>
                            <input v-model="size.height" type="number" :min="1" class=" text-right p-1 border border-gray-500 w-[250px] outline-none rounded focus:ring-1 ring-accent"
                            :class="[size.height && validate(size.height) && 'ring-1 ring-red-600 border-none' ]">
                            <span class="text-xs text-accent text-right">{{units === 'metric'? 'from 20 cm' : 'from 8 inch'}}</span>
                        </div>
                    </div>
                    <span v-if="size.width && size.height && !(validate(size.height) || validate(size.width) )" class="py-2">Preview:</span>
                    <div v-if="size.width && size.height && !(validate(size.height) || validate(size.width) )"  class="bg-gray- border  flex justify-center p-2">
                        <div class="h-[200px] w-[200px] flex justify-center items-center">
                            <div class="bg-accent" :style="{ width: width(), height: height()}"></div>
                        </div>
                    </div>
                    <div class="py-4 flex flex-col gap-y-2">
                        <span>Poster price:</span>
                        <span v-if="size.width && size.height && !(validate(size.height) || validate(size.width) )">{{price}} €</span>
                        <span v-if="size.width && size.height && (validate(size.height) || validate(size.width) )" class="text-red-400"> Please enter valid size!</span>
                        <span v-if="!size.width || !size.height" class="italic text-accent">Fill width and height to see price</span>
                    </div>
                </div>
                <!-- dialog footer -->
                <div class="w-full h-16 flex gap-x-2 justify-center">
                    <button
                        @click="cancel()"
                        class="text-lg h-10 border border-gray-800 text-gray-800 transition-all ease-in-out hover:bg-gray-800 font-medium capitalize px-8 focus:outline-none select-none hover:text-white"
                    >
                        cancel
                    </button>
                    <button
                        :disabled="!valid()"
                        @click="submit()"
                        :class="[ valid()&&'bg-accent hover:bg-gray-800', !valid()&&'bg-gray-400', 'text-lg h-10 border font-medium capitalize text-white  transition-all ease-in-out  px-8 focus:outline-none select-none' ]"
                    >
                        <span v-if="true">Continue</span>
                        <svg
                            v-if="false"
                            class="animate-spin h-10"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="30px"
                            height="30px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                            style="
                                margin-right: -2px;
                                display: block;
                                background-repeat-y: initial;
                                background-repeat-x: initial;
                            "
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="32"
                                stroke-width="9"
                                stroke="#ffff"
                                stroke-dasharray="50.26548245743669 50.26548245743669"
                                fill="none"
                                stroke-linecap="round"
                            ></circle>
                        </svg>
                    </button>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    computed:{
        customSizeModal() {return this.$store.getters.customSizeModal},
        productStepVariantAttribute(){return this.$store.getters.productStepVariantAttribute},

        price(){
            let w = this.units === 'imperial' ? this.size.width * 2.54 : this.size.width,
                h = this.units === 'imperial' ? this.size.height * 2.54 : this.size.height,
                priceM2 = 28,
                price = (w * h / 10000) * priceM2

            return price.toFixed(2) < 5? '5.00': price.toFixed(2)
        }
    },
    methods:{
        
        cancel(){
            this.$store.dispatch('setCustomSizeModal', false)
            this.size = {
                        width: null,
                        height: null
                    }
        },
        submit(){
            this.setVariantID()
        },

        setVariantID() {
            let unit = this.units,
                w = unit === 'imperial' ? this.size.width * 25.4 : this.size.width * 10,
                h = unit === 'imperial' ? this.size.height * 25.4 : this.size.height * 10,
                value = `?width=${w}?height=${h}`

            this.$store.dispatch("setProductStepVariantID", value).then(() => {
                
                    this.goToDesigns();
                    this.size = {
                        width: null,
                        height: null
                    }
               
            });
        },

        goToDesigns() {
            //check if user or unauthentificated guest, popup login
            this.$store.dispatch('goToDesignsStep')
            this.cancel()

        },

        validate(value){
            
            let max = this.units === 'imperial' ? this.maxInch : this.maxCm,
                min = this.units === 'imperial' ? this.baseInch : this.baseCm

            if(value >= min && max >= value){
                return false
            }

            return true
        },
        valid(){
            let w = this.size.width,
                h = this.size.height

            if(w && h && this.validate(w) || this.validate(h)) return false
            return true
        },

        width(){
            let w = this.size.width,
                h = this.size.height

            if(h>w){
            return w/h * 100 + '%'
            }  
            return 100+'%'
        },

        height(){
            let w = this.size.width,
                h = this.size.height

            if(w>h){
                return h/w * 100+'%'
            }    
            return 100+'%'
        }


    },
    data() {
        return {
            units: "metric",
            baseInch: 8, //min size inches
            baseCm: 20, //min size CM
            maxInch: 55,
            maxCm: 140,
            size: {
                width: null,
                height: null
            }
        };
    },
    name: "custom size modal",
    components: {  }
}
</script>

<style scoped>

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

</style>