


const searchDesign = {

    state:{
        designSearchKeyword: null
    },

    mutations: {
        setSearchKeyword: (state, keyword)=> state.designSearchKeyword = keyword
    },

actions:{
    setSearchKeyword({commit}, keyword){
        commit('setSearchKeyword', keyword)
    },
    changeDesignNameInEditor({commit}){
        // let design = this.getters.currentDesign
        
        commit()
    },

    resetSearchKeyword( {commit}){
        commit('setSearchKeyword', null)
    }
},

getters:{
    designSearchKeyword: state => state.designSearchKeyword
}

}

export default searchDesign