import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const updateProduct = {

    state:{ 
        currentProduct: null
    },

    mutations:{
        setCurrentProduct:(state, product)=>{
            state.currentProduct = product
        },
        resetCurrentProduct:(state)=>{
            state.currentProduct = null
        },
    },
    actions: {
        publishSelectedProducts({ dispatch }, designId) {
            let products = this.getters.productsToBeEnabled;
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl +
                            `/api/client/designs/${designId}/products/publish`, {params: { products: products }}
                    )
                    .then((response) => {
                        dispatch("getDesignsList", [12]);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        publishProduct({ dispatch }, [designId, products]) {
            
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl +
                            `/api/client/designs/${designId}/products/publish`, {params: { products: products }}
                    )
                    .then((response) => {
                        dispatch("getDesignsList", [12]);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },


        unpublishProduct({dispatch}, [designId, productId]) {

            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/client/designs/${designId}/products/${productId}/unpublish`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        unpublishDesign({ dispatch }, designId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/client/designs/${designId}/unpublish`)
                    .then((response) => {
                        dispatch(
                            "getDesignsList",
                            this.getters.designsPagination.current_page
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        setCurrentProduct({commit}, product){
            commit('setCurrentProduct', product)
        },
        resetCurrentProduct({commit}){
            commit('resetCurrentProduct')
        }

        
    },

    getters:{
        currentProduct: state => state.currentProduct
    },
};

export default updateProduct;
