import { createStore } from "vuex";

import utilities from "./utilities/utilities";
import productSteps from "./productSteps/productSteps";
import productGallery from "../modules/designs/products/store/productGallery";
import products from "./products/products";
import orders from "../modules/orders/store/orders";
import invoices from "../modules/invoices/store/invoices";
import settings from "../modules/settings/store/settings";
import basket from "../modules/basket/basket";
import payments from "../common/components/modals/paymentModal/store/payments";

import navigation from "../modules/navigation/store/navigation";
import notifications from "../common/components/notifications/store/notifications";
import designGallery from "../modules/designs/designList/store/designGallery";
import auth from "../common/components/modals/authPopup/store/auth";
import resetPassword from "./resetPassword";
import viewOrder from "./viewOrder";
import changeImageLinkModal from "../common/components/modals/changeImageLinkModal/changeImageLinkModal";
import productList from "../modules/productList/store/productList";
import activityLogAPI from '../modules/activityLog/store/activityLogAPI';
import customSizeModal from '../common/components/modals/customSizeModal/store/customSizeModal';
import about from '../modules/about/store/about';
import announcment from '../common/components/snackbars/announcment';





export default createStore({
    state: {
        errorStatus: false,
        errorMessage: null,
    },

    mutations: {
        resetErrors(state) {
            state.errorStatus = false;
            state.errorMessage = null;
        },
    },
    actions: {
        clearAll({ commit }) {
            commit("resetDesignList");
            commit("resetDesignGallery");
            commit("resetInvoices");
            commit("resetOrderOperations");
            commit("resetOrderTable");
            commit("resetPayments");
            commit("resetProductGallery");
            commit("resetCurrentProduct");
            commit("resetconfigure");
            commit("setCurrentProductStep", "design");
            commit("setVariantList", null);
            commit("resetCurrentDesign");
            commit("resetSettings");
            commit("resetSettings");
        },
    },
    getters: {
        errorStatus(state) {
            return state.errorStatus;
        },
        errorMessage(state) {
            return state.errorMessage;
        },
    },
    modules: {
        auth,
        designGallery,
        productSteps,
        utilities,
        productGallery,
        products,
        orders,
        invoices,
        settings,
        basket,
        payments,
        notifications,
        navigation,
        resetPassword,
        viewOrder,
        changeImageLinkModal,
        productList,
        activityLogAPI,
        customSizeModal,
        about,
        announcment
    },
});
