

import axios from "axios";
import photoCalendar from './photoCalendar';
import deskCalendar from './deskCalendar';
import router from '../../../router';

var baseUrl = process.env.VUE_APP_BASE_URL;



const calendars = {
    actions: {
        uploadMultipleDesigns({ commit, dispatch }, event) {
            const productID = this.getters.currentDesign?.client_product?.client_product_id
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Uploading design",
                color: "bg-accent",
            });
            
            let files = Array.from(event.target.files)
            var formData = new FormData();
            if(files.length){
                files.forEach(file => formData.append("calendar_designs[]", file))
            }

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/client/products/${productID}/upload-designs`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                    })
                    .then((response) => {
                        if(this.getters.currentDesign?.client_product.client_product_type_id === 10){
                            const variantID = router.currentRoute.value.params.variantID
                            dispatch('getDesignVariants',[10, {cd_id: this.getters.currentDesign?.client_design?.cd_id, sku_id: variantID}])
                        } 

                        if(this.getters.currentDesign?.client_product.client_product_type_id !== 10){
                            dispatch("setCurrentDesign", productID);
                        } 
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Upload Successful!",
                            timeout: 2500,
                        });
                        files = null
                        resolve(response);
                    })
                    .catch((error) => {
                        files = null
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem uploading file!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        addDesignsToCalendar({ commit, dispatch },  designs) {
            const productID = this.getters.currentDesign?.client_product?.client_product_id
            dispatch("setLoading");
            dispatch("setSnackbar", {
                active: true,
                text: "Adding designs to product",
                color: "bg-accent",
            });
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/client/products/${productID}/add-designs`, { client_designs: designs})
                    .then((response) => {
                        if(this.getters.currentDesign?.client_product.client_product_type_id === 10){
                            const variantID = router.currentRoute.value.params.variantID
                            dispatch('getDesignVariants',[10, {cd_id: this.getters.currentDesign?.client_design?.cd_id, sku_id: variantID}])
                        } 

                        if(this.getters.currentDesign?.client_product.client_product_type_id !== 10){
                            dispatch("setCurrentDesign", productID);
                        } 
                        
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Designs added!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem adding designs!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        removeDesignsFromCalendar({ commit, dispatch },  designID) {
            const productID = this.getters.currentDesign?.client_product?.client_product_id
            dispatch("setLoading");
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + `/api/client/products/${productID}/remove-design  `, {data: { cd_id: designID}})
                    .then((response) => {
                            dispatch(
                                "setCurrentDesign", productID
                            );
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Design removed!",
                            timeout: 2500,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem removing design!",
                            timeout: 8000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setUserErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        setCalendarPageDesign({commit}, [newPage, dropItem]){
            let list = this.getters.calendarList

            let index = this.getters.calendarList.findIndex(page => page.cvp_id === newPage.cvp_id)

            if(index > -1){
                list[index].cvp_data.client_design = dropItem
            }
            commit('updatePhotoCalendarList', list)
        }
    },

    modules:{
        photoCalendar,
        deskCalendar
    }
};

export default calendars;
