import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;



const notificationsMarkAsRead = {

actions:{
    notificationMarkAsRead({ dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + `/api/notifications`)
                .then((response) => {
                    dispatch("getUser");
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.status);
                    if (error.response.status === 401) {
                        dispatch("destroyToken");
                    }
                    reject(error);
                });
        });
    },
}

}

export default notificationsMarkAsRead