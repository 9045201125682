import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const payments = {
    state: {
        activeView: "paymentMethods",
        paymentsDialog: false,
        sessionId: null,
        quedItemsToPay: null
    },
    mutations: {
        setPaymentsDialog: (state, newState) =>
            (state.paymentsDialog = newState),
        setPaymentsActiveView: (state, nextView) =>
            (state.activeView = nextView),
        setSessionId: (state, id) => (state.sessionId = id),

        resetPayments(state) {
            (state.activeView = "paymentMethods"),
                (state.paymentsDialog = false),
                (state.sessionId = null);
        },

        setQuedItems(state, items){
            state.quedItemsToPay = items
        },

        clearQuedItems(state){
            state.quedItemsToPay = null
        }
    },

    actions: {
        setPaymentsDialog({ commit }, state) {
            commit("setPaymentsDialog", state);
        },
        setPaymentsActiveView({ commit }, view) {
            commit("setPaymentsActiveView", view);
        },

        setPayment({ commit, dispatch }, [paymentType,items]) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/${paymentType}/pay`, items)
                    .then((response) => {
                        commit("setSessionId", response.data.id);
                        resolve(response);
                    })
                    .catch((error) => {

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: error.response.data.message,
                            timeout: 4000,
                        });

                        dispatch("setPaymentsDialog", false);

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
        resetPayments({commit}){
            commit('resetPayments')
        },

        setQuedItems({commit}, items){
            commit('setQuedItems', items)
        },

        clearQuedItems({commit}){
            commit('clearQuedItems')
        }
    },

    getters: {
        paymentsActiveView: (state) => state.activeView,
        paymentsDialog: (state) => state.paymentsDialog,
        sessionId: (state) => state.sessionId,
        quedItemsToPay: (state) => state.quedItemsToPay,
    },
};

export default payments;
