import axios from "axios";
import productListNavigation from './productListNavigation'
import editor from './editor';

var baseUrl = process.env.VUE_APP_BASE_URL;



const productList = {

modules:{
    productListNavigation, editor
},
    
state:{
    products: null,
    productVariants: null
},

mutations:{
    setProducts(state, products){
        state.products = products
    },
    setProductVariants: (state, variants)=> state.productVariants = variants
},

actions:{
    getProducts({commit, dispatch}){
        return new Promise((resolve, reject)=> {
            axios
            .get(baseUrl + `/api/products`)
            .then((response) => {
                commit("setProducts", response.data.data);
                resolve(response);
            })
            .catch((error) => {
                console.log(error.response.status);
                if (error.response.status === 401) {
                    dispatch("destroyToken");
                }
                reject(error);
            });
        })
       
    },

    getProductVariants({commit, dispatch}, id){

        if(id){
            return new Promise((resolve, reject)=> {
                axios
                .get(baseUrl + `/api/products/${id}/create`)
                .then((response) => {
                    commit("setProductVariants", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.status);
                    if (error.response.status === 401) {
                        dispatch("destroyToken");
                    }
                    reject(error);
                });
            })
        }


    },


},



getters:{
    products: state => state.products,
    productVariants: state => state.productVariants
}

}

export default productList