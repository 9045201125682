import framedPosters from "./framedPosters/framedPosters";
import canvas from './canvas/canvas';
import hangerPoster from './hangerPoster/hangerPoster';
import cards from './cards/cards';
import calendars from './calendars/calendars';





const products = {
    state: {},

    mutations: {},
    actions: {},

    getters: {},
    modules: {
        framedPosters,
        canvas,
        hangerPoster,
        cards,
        calendars
    },
};

export default products;
