const designPublishing = {
    mutations: {},

    actions: {
        changeDesignStatus({ commit }, [designId, status]) {
            let designs = this.getters.designsList
            if( designs){
                designs.forEach((design) => {
                    if (design.cd_id === designId) {
                        (design.cd_publishing_status.ps_id = status.ps_id),
                            (design.cd_publishing_status.ps_name = status.ps_name),
                            (design.cd_publishing_status.ps_display_name =
                                status.ps_display_name);
                    }
                });
            }
            

            commit("setOnlyDesigns", designs);
        },
    },
};

export default designPublishing;
