<template>
    <transition name="fade">
        <div
            v-show="loginModal"
            @click="closeModal()"
            class="z-50 absolute inset-0 flex bg-white bg-opacity-60 justify-center items-center"
        >
            <div
                @click.stop
                class="modal lg:rounded-xl xl:shadow-lg bg-white w-full h-full lg:h-auto lg:w-2/4 2xl:w-[30%] flex flex-col items-center py-4 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-paleGrey scrollbar-thumb-rounded transition duration-300 ease-in-out"
            >
                <!-- modal stuff -->
                <!-- logo -->
                <div
                    class="image h-32 w-32 bg-contain bg-no-repeat bg-center"
                    :style="{
                        backgroundImage:
                            'url(' +
                            require('@/assets/PP_register_logo.png') +
                            ')',
                    }"
                />

                <!-- forms -->

                <login-form v-if="activeAuthModalForm === 'login-form'" />
                <forgot-password-form
                    v-if="activeAuthModalForm === 'forgot-password-form'"
                />
                <register-form v-if="activeAuthModalForm === 'register-form'" />
                <welcome-popup v-if="$route.name === 'welcome' && false" />
                <registration-popup v-if="registrationPopup" />
                <guest-user-login-form
                    v-if="activeAuthModalForm === 'guest-user-login'"
                />

                <!-- while waiting  -->
                <svg
                    v-if="activeAuthModalForm === 'loading'"
                    class="animate-spin h-16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="30px"
                    height="211px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    style="
                        margin-right: -2px;
                        display: block;
                        background-repeat-y: initial;
                        background-repeat-x: initial;
                    "
                >
                    <circle
                        cx="50"
                        cy="50"
                        r="32"
                        stroke-width="9"
                        stroke="#651fff"
                        stroke-dasharray="50.26548245743669 50.26548245743669"
                        fill="none"
                        stroke-linecap="round"
                    ></circle>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";

import ForgotPasswordForm from "./components/forgotPasswordForm.vue";
import LoginForm from "./components/loginForm.vue";
import RegistrationPopup from "./components/registrationPopup.vue";
import RegisterForm from "./components/registerForm.vue";

import GuestUserLoginForm from "./components/guestUserLoginForm.vue";
import WelcomePopup from "../welcomePopup/welcomePopup.vue";

export default {
    computed: {
        ...mapGetters([
            "activeAuthModalForm",
            "registrationPopup",
            "loginModal",
        ]),
    },
    components: {
        ForgotPasswordForm,
        LoginForm,
        RegistrationPopup,
        RegisterForm,
        GuestUserLoginForm,
        WelcomePopup,
    },
    methods: {
        closeModal() {
            this.$store.dispatch("setLoginModal", false);
        },
    },
    name: "authPopup",
};
</script>

<style scoped>
.modal {
    max-height: 100%;

    @media only screen and (max-width: 600px) {
        max-width: 710px;
    }
}

.image {
    min-height: 128px;
    min-width: 128px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
