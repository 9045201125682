const configureHelpers = require("../helpers/configure");
import store from "../../store"

function saveVariants(getters) {
    // const routeName = router.currentRoute.value.matched[0].name
    const PRODUCT_ID =
        getters.currentDesign.client_product.client_product_type_id;

    let variantList = getters.variantList;
    let calendarList = getters.calendarList;
    let currentDesign = getters.currentDesign;

    let client_variants = null;
    let product = null;

    if (PRODUCT_ID < 6) {
        if (PRODUCT_ID === 3) {
            store.dispatch("setValuesToCanvasVariants");
        }

        let clientProduct = getters.currentDesign.client_product;

        product = {
            cp_id: clientProduct.client_product_id,
            cp_description: clientProduct.client_product_description,
        };

        if (PRODUCT_ID !== 2) {
            //attribute name
            let attribute =
                Object.keys(
                    clientProduct.client_product_attributes
                )[0].toString() || null;

            //selected attribute in array
            let attributeArray =
                clientProduct.client_product_attributes[attribute];
            product[attribute] = attributeArray;
        }

        client_variants = variantList.map((variant) => {
            let newVariant = {
                cv_active: variant.cv_active,
                cv_id: variant.cv_id,
                cv_start_x: variant.cv_start_x,
                cv_start_y: variant.cv_start_y,
                cv_canvas_width: variant.cv_canvas_width,
                cv_canvas_height: variant.cv_canvas_height,
                cv_crop_w: variant.cv_crop_w,
                cv_crop_h: variant.cv_crop_h,
                cv_rotate: variant.cv_rotate,
                cv_scale_x: variant.cv_scale_x,
                cv_scale_y: variant.cv_scale_y,
                cv_retail_price: variant.cv_retail_price,
                cv_markup: variant.cv_markup,
                cv_data: variant.cv_data,
                cv_quality: variant.quality,
            };
            return newVariant;
        });
    }

    if (PRODUCT_ID > 5) {
        let clientProduct = getters.currentDesign.client_product;
        //attribute name
        let attribute = Object.keys(
            clientProduct.client_product_attributes
        )[0].toString();

        //decapitelized attribute string
        let newAttribute =
            (attribute && attribute[0].toLowerCase() + attribute.slice(1)) ||
            attribute;

        //selected attribute in array
        let attributeArray = clientProduct.client_product_attributes[attribute];

        product = {
            cp_id: clientProduct.client_product_id,
            cp_description: clientProduct.client_product_description,
            [newAttribute]: attributeArray,
        };

        if (PRODUCT_ID === 6) {
            product.prices =
                clientProduct.client_product_attributes.prices ||
                clientProduct.client_product_prices.prices;
        }

        let mappedList = calendarList.map((page) => {
            let newItem = {
                cvp_month: page?.cvp_month,
                cvp_data: {
                    cvp_quality: page?.quality,
                    cvp_cd_id: page?.cvp_data?.client_design?.cd_id,
                    cvp_rotate: page?.cvp_data?.cvp_rotate,
                    cvp_zoom: page?.cvp_data?.cvp_zoom,
                    cvp_start_x: page?.cvp_data?.cvp_start_x,
                    cvp_start_y: page?.cvp_data?.cvp_start_y,
                    cvp_crop_w: page?.cvp_data?.cvp_crop_w,
                    cvp_crop_h: page?.cvp_data?.cvp_crop_h,
                    cvp_canvas_width:
                        page?.cvp_data?.cv_data?.cvcd_canvas_width,
                    cvp_canvas_height:
                        page?.cvp_data?.cv_data?.cvcd_canvas_height,
                    cvp_scale_x: page?.cvp_data?.cvp_scale_x,
                    cvp_scale_y: page?.cvp_data?.cvp_scale_y,
                    cv_data: page?.cvp_data?.cv_data,
                },
            };
            return newItem;
        });

        client_variants = [
            {
                cv_id: currentDesign?.client_product?.client_variants?.cv_id,
                cv_active: true,
                cv_pages: mappedList,
            },
        ];
    }

    let selectedProductProporties = configureHelpers.returnProductProporty(
        PRODUCT_ID,
        getters.configureProporties
    );

    if (selectedProductProporties) {
        let attribute = Object.keys(selectedProductProporties)[0];

        product[attribute] = Array;
        product[attribute] = selectedProductProporties[attribute];
    }

    let variantData = {
        client_variants,
        product,
    };

    return variantData;
}

export default { saveVariants };
