<template>
    <div class="flex flex-col">
        <div v-if="label">
            <label for="formInput"
                >{{ label }}
                <span class="text-xs">{{ labelSmall }}</span></label
            >
            <span
                v-if="required"
                :class="
                    fieldValidation() === 1
                        ? 'text-red-500 text-xl font-semibold px-1'
                        : fieldValidation()
                "
                >{{ fieldValidation() === 1 ? "*" : null }}</span
            >
        </div>
        <slot name="image" />
        <input
            ref="formInput"
            name="formInput"
            :type="type"
            class="ring-2 h-8 px-2 ring-gray-300 focus:ring-violet-400 outline-none "
            :class="label ? 'mt-3' : ''"
            :style="{ width: width + 'px' }"
            autocomplete="off"
            :disabled="disabled"
            :value="inputValue"
            @input="handleInput"
        />
    </div>
</template>

<script>
export default {
    created() {
        this.startingValue = this.inputValue;
    },
    emits: ["update:inputValue"],

    computed: {},
    methods: {
        handleInput(e) {
            this.startingValue = null;
            this.$emit("update:inputValue", e.target.value);
        },

        fieldValidation() {
            if (this.startingValue !== this.inputValue) {
                if (this.inputValue === "") {
                    return "mdi mdi-close-circle text-lg text-red-500 px-1";
                }

                if (this.type === "password" && this.inputValue.length > 7) {
                    return "mdi mdi-check-circle text-lg text-green-500 px-1";
                }

                if (this.type === "password" && this.inputValue.length < 8) {
                    return "mdi mdi-close-circle text-lg text-red-500 px-1";
                }

                if (this.type !== "password" && this.inputValue.length) {
                    return "mdi mdi-check-circle text-lg text-green-500 px-1";
                }
            }

            return 1;
        },
    },

    props: {
        label: { default: null, required: false, type: String },
        labelSmall: { default: null, required: false, type: String },
        disabled: { default: false, required: false, type: Boolean },
        required: { default: false, type: Boolean },
        inputValue: {
            type: [String, Number],
            default: null,
        },

        type: { type: String, default: "text",  },
        width: { type: String, default: "100" },
    },

    data() {
        return {
            validationFormating: null,
            startingValue: null,
        };
    },

    name: "formInput",
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
