
import designList from './designList';
import designUpload from './designUpload';
import designPublishing from './designPublishing';




const designGallery = {
    state: { 
        activeView: "designsGallery",
        designs: null,
    },

    mutations: {
        setActiveView(state, view){
            state.activeView = view
        },
        resetDesignGallery(state){
            state.activeView = 'designsGallery',
            state.designs = null
        }
    },
    actions: {

        setActiveView({ commit}, view){ // designsGallery, ProductGallery, productSteps
            commit("setActiveView", view)
        }
    },

    getters: {
        activeView: (state) => state.activeView,
    },

    modules:{
        designUpload,
        designList,
        designPublishing
    }
};

export default designGallery;
