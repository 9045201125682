import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const editor = {
    actions: {
        getDesignVariants(
            { commit, dispatch, state },
            [productID, body]
        ) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        baseUrl + `/api/products/${productID}/create-variant`,
                        body 
                    )
                    .then((response) => {
                        //update calendar design list without changing pages data

                        let calendarIDs = [6, 7, 8, 10];

                        if (
                            calendarIDs.includes(
                                response.data.data.client_product
                                    .client_product_type_id
                            )
                        ) {
                            if (state.currentDesign) {
                                
                                let oldPagesData =
                                    state.currentDesign.client_product
                                        .client_variants.cv_pages;

                                let newData = response.data.data;
                                newData.client_product.client_variants.cv_pages = oldPagesData;

                                commit("setCurrentDesign", newData);
                            }
                            
                            commit("setCurrentDesign", response.data.data );
                        } else {
                            if (state.currentDesign) {
                                let oldVariants =
                                    state.currentDesign.client_product
                                        .client_variants;
                                let newData = response.data.data;
                                newData.client_product.client_variants = oldVariants;

                                commit("setCurrentDesign", newData);
                            }
                            commit("setCurrentDesign", response.data.data);
                        }


                        let variants = [response.data.data.client_product.client_variants]
                        
                        if (
                            calendarIDs.includes(
                                response.data.data.client_product
                                    .client_product_type_id
                            )
                        ) {
                             variants = response.data.data.client_product.client_variants
                            
                        }

                        dispatch(
                            "setVariantList", variants
                        );

                        if (
                            response.data.data.client_product
                                .client_product_type_id === 1
                        ) {
                            dispatch(
                                "setFrameVariants",
                                response.data.data.client_product
                                    .client_product_attributes.frame
                            );
                        }

                        if (
                            response.data.data.client_product
                                .client_product_type_id === 3
                        ) {
                            dispatch(
                                "setCanvasWrapVariants",
                                response.data.data.client_product
                                    .client_product_attributes.Edges
                            );
                        }

                        if (
                            response.data.data.client_product
                                .client_product_type_id === 5
                        ) {
                            dispatch(
                                "setHangerVariants",
                                response.data.data.client_product
                                    .client_product_attributes.hanger
                            );
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },
};
export default editor;
