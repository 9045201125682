import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import mitt from "mitt";

var captcha_key = process.env.VUE_APP_RECAPTCHA_KEY;

import AppLayout from './layouts/AppLayout.vue';

const emitter = mitt();

const app = createApp(App);

// Initialize GTM Data Layer
window.dataLayer = window.dataLayer || [];

// Push initial page view to data layer
window.dataLayer.push({
    event: 'pageview',
    pagePath: window.location.pathname,
});

app.use(store)
    .use(router)
    .component('AppLayout', AppLayout)
    .mount("#app");

app.config.globalProperties.captcha_key = captcha_key;
app.config.globalProperties.emitter = emitter;

// Track page views with Vue Router
router.afterEach((to) => {
    window.dataLayer.push({
        event: 'pageview',
        pagePath: to.path,
    });
});
