<template>
    <div
        @click.self="closeDialog()"
        class="absolute inset-0 bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center"
    >
        <div
            class="bg-white w-full lg:w-1/2 h-full lg:h-5/6 flex flex-col rounded-3xl"
            style="min-height: 256px;"
        >
            <div
                class="relative border-b text-white w-full h-16 rounded-t-3xl flex justify-center items-center"
            >
                <span class="text-accent font-medium"
                    >Terms and Conditions</span
                >
                <div
                    @click="closeDialog()"
                    class="absolute right-4 text-white w-5 h-5 bg-red-400 hover:bg-red-500 rounded-full text-center text-sm cursor-pointer"
                >
                    <span class="mdi mdi-close"></span>
                </div>
            </div>
            <div class="text-sm overflow-y-auto mb-5 px-6 pt-5">
                <p>
                    PRINTPIGEONS Terms of Use shall be binding to every user,
                    who uses PRINTPIGEONS services. <br />PRINTPIGEONS shall
                    have the right to change Terms of Use from time to time.
                    Please reread the Terms of Use before placing a repeated
                    order.
                </p>
                <h3 class="font-semibold"><br />User Rights and Obligations</h3>
                <p>
                    <br />1. User shall be responsible for all activities
                    performed in User’s registered user account and information
                    provided by User himself/herself, as well as about the
                    confidentiality of the password and user account. <br />2.
                    User shall not have the right to transfer User’s details
                    (user name and password) to third persons. User’s details
                    can be used solely by the particular user (a natural or
                    legal person), to whom they have been awarded. <br />3. User
                    is not allowed to execute such actions that are targeted
                    against system security. In case of stating any breaches,
                    PRINTPIGEONS shall retain the right to unilaterally
                    terminate provision of the service, without giving prior
                    notice about that and without explaining the reasons for it.
                    <br />4. User shall bear full responsibility for observing
                    the legislative norms of copyrights regarding all images
                    used. By uploading the images, User guarantees that he/she
                    is the owner of the respective image copyrights or that the
                    respective images have been legally acquired and that the
                    User has the right to use these images without any
                    limitations. <br />5. When placing the order, User approves
                    that he/she has read and agrees with PRINTPIGEONS Terms and
                    conditions. <br />6. When placing the order User agrees that
                    the processing of the submitted data is executed by
                    PRINTPIGEONS in accordance with the Personal Data Protection
                    Law.
                </p>
                <h3 class="font-semibold"><br />PRINTPIGEONS Rights and Obligations</h3>
                <p>
                    <br />1. PRINTPIGEONS offers User the service to create
                    his/her private user account at the web site, using which
                    the account user himself/herself is able to create various
                    print products according to the development modules offered
                    by PRINTPIGEONS. PRINTPIGEONS provides the production of the
                    particular product and organisation of its delivery.
                    <br />2. PRINTPIGEONS undertakes to provide the receiving of
                    the product at the agreed time and in the agreed amount
                    according to the product and service description. <br />3.
                    PRINTPIGEONS shall have the right to use the images uploaded
                    by the User only for the production of the order, that has
                    been placed by the User.
                </p>
                <h3 class="font-semibold"><br />Registration</h3>
                <p>
                    <br />1. To begin successful cooperation with PRINTPIGEONS,
                    you have to create your user account, that shall be
                    considered as a lawful agreement between the account user
                    and the service provider. <br />2. By registering and
                    agreeing to become a user of PRINTPIGEONS account, you have
                    to provide your name, surname, actual place of residence,
                    date of birth, existing e-mail address and must create a
                    password. By entering the password you undertake
                    responsibility for the password safety and confidentiality.
                </p>
                <h3 class="font-semibold"><br />Purchase policy</h3>
                <p>
                    <br />1. To place an order in PRINTPIGEONS online shop, the
                    User has to registered in the PRINTPIGEONS web page or
                    register prior to the purchase. <br />2. The User chooses a
                    product in the online shop and opens its electronic product
                    form. <br />3. The User uploads a picture, text or design
                    for the product and creates the desired layout in the
                    electronic product form. <br />4. The User enters the
                    preview mode to be sure that the created product corresponds
                    with the desired result and confirms the mock-up by
                    proceeding to the cart. <br />5. The User enters his/her
                    delivery and invoice address. <br />6. The User chooses the
                    desired type of delivery. <br />7. The User makes payment
                    choosing a payment method. <br />8. The User receives
                    his/her order according to the chosen type and terms of
                    delivery.
                </p>
                <h3 class="font-semibold"><br />Confidentiality</h3>
                <p>
                    <br />1. PRINTPIGEONS guarantees storing of your information
                    and not disclosing it to third persons. <br />2.
                    PRINTPIGEONS uses and stores your personal information for
                    the following purposes:to administer liabilities with the
                    client, i.e. to be able to provide the order development,
                    execution and organisation of its delivery.
                </p>
                <h3 class="font-semibold">
                    <br />Development of product print file and print quality
                </h3>
                <p>
                    <br />1. User of PRINTPIGEONS account creates the chosen
                    print product layout, by uploading the images to the
                    electronic product form created by PRINTPIGEONS and by
                    choosing the offered layout types. By confirming the placed
                    order, user undertakes full responsibility for the quality
                    and layout of the images that he/she has inserted in the
                    provided product templates. <br />2. If necessary, upon
                    separate agreement for a definite charge and within an
                    agreed period of time the print product layout can be
                    developed by PRINTPIGEONS. <br />3. User shall be
                    responsible for the quality of the images used in the
                    layout. Please point your attention to warning signs that
                    are displayed automatically when uploading images of low
                    quality. User is warned about the consequences, when using
                    low quality images to create product. PRINTPIGEONS does not
                    accept any claims regarding print quality in such cases.
                    <br />4. If User has claims regarding nonconformity of the
                    produced product quality to general polygraphy standards,
                    User shall have the right to return the received production
                    within 14 (fourteen) working days by delivering the ready
                    product to PRINTPIGEONS office in person or by using courier
                    services (at User’s expense), in such case informing
                    PRINTPIGEONS about it by phone or e-mail and by filling in
                    an application of a definite sample. All cases shall be
                    individually reviewed and solved within 14 (fourteen)
                    working days. If claims are reasoned, upon agreement with
                    the client, order shall be reprinted or the received charge
                    shall be returned to the bank account provided by the User
                    within 14 (fourteen) working days.
                </p>
                <h3 class="font-semibold"><br />Order confirmation</h3>
                <p>
                    <br />1. Before confirming the order of the created product,
                    User shall be obliged to check the created layout. To fill
                    in the provided fields upon placing the order and to provide
                    existing e-mail and phone number.
                </p>
                <h3 class="font-semibold"><br />Payments</h3>
                <p>
                    <br />1. All prices are provided in USD with VAT (20%).
                    Order execution is begun only after the payment is made.
                    <br />2. You can also pay via your PayPal account. Upon
                    choosing to pay via PayPal you will be connected to your
                    PayPal account. <br />3. You can pay for products ordered at
                    PRINTPIGEONS on the Internet, using payment cards – both
                    credit and debit cards. We accept payments by Visa, Visa
                    Electron, Mastercard, Maestro payment cards. The
                    confidentiality of payment card data is provided by
                    Braintree.
                </p>
                <h3 class="font-semibold"><br />Production period</h3>
                <p>
                    <br />1. Period of executing the order shall be considered
                    starting from the moment, when the User has made the payment
                    for the service and received PRINTPIGEONS confirmation till
                    the moment, when the particular product may be received at
                    PRINTPIGEONS office. <br />2. Standard period of executing
                    the order is 2-4 working days. When the order is ready, you
                    will receive an e-mail at your provided e-mail address.
                    <br />3. Order will be handed over for delivery.
                </p>
                <h3 class="font-semibold"><br />Delivery policy</h3>
                <p>
                    <br />1. Delivery time after the payment (including the time
                    of processing and producing the order) maybe 3-14 working
                    days - depending on the chosen product and selected type of
                    receiving the order.
                </p>
                <h3 class="font-semibold">Order delivery types</h3>
                <p>
                    <br />1. United States Postal Service in the territory of
                    United States of America. Order delivery to your given
                    address in the USA per average takes 3 - 8 working days
                    after the order is manufactured. <br />* Excluded countries:
                    Central African Republic, Comoros, Cuba, Equatorial Guinea,
                    Falklands, Guinea Bissau, Iran, Johnston Island, Kiribati,
                    North Korea, Mayotte Island, Myanmar, Nauru, Russian
                    Federation, Saint Pierre Et Miquelon, Sao Tome & Principe,
                    Sierra Leone, Solomon Islands, Somalia, St. Helena, Sudan,
                    Syria, Tajikistan, Tokelau Islands, Republic Of
                    Turkmenistan, Tuvalu, Wake Islands. <br />
                    3. If the User hasn't received his/her order in 10 days time
                    from the day the order has been dispatched (the day you have
                    received our e-mail confirming that the order has been
                    dispatched), the User ought to contacy PRINTPIGEONS via
                    e-mail: <strong>yes@printpigeons.com</strong>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(["termsPopup"]),
    },

    methods: {
        closeDialog() {
            this.$store.dispatch("setTermsPopup", false);
        },
    },
    name: "termsAndConditions",
};
</script>

<style></style>
