var helpers = require("@/common/helpers/variants.js");

const selectedVariant = {
    state: {
        selectedVariant: null,
    },

    mutations: {
        setSelectedVariant: (state, variant) => {
            state.selectedVariant = variant;
        },
    },

    actions: {
        setSelectedVariant({ commit }, variant) {
            commit("setSelectedVariant", variant);
        },

        setCanvasSelectedVariant({ commit, state }) {
            const VARIANT_LIST = this.getters.variantList;
            const SELECTED_VARIANT = state.selectedVariant;
            const SELECTED_CANVAS_WRAP_TO_EDIT = this.getters
                .selectedWrapToEdit;

            let variants = VARIANT_LIST.filter(
                (variant) =>
                    variant.cv_name_inch === SELECTED_VARIANT.cv_name_inch
            );

            let selectedCanvasVariant = variants.filter(
                (variant) =>
                    variant.cv_attribute_value === SELECTED_CANVAS_WRAP_TO_EDIT
            )[0];
            commit("setSelectedVariant", selectedCanvasVariant);
        },

        setCardsSelectedVariant({ commit, state }) {
            const VARIANT_LIST = this.getters.variantList;
            const SELECTED_VARIANT = state.selectedVariant;
            const SELECTED_CARDS_STYLE_TO_EDIT = this.getters.selectedCardType;

            let variants = VARIANT_LIST.filter(
                (variant) =>
                    variant.cv_name_inch === SELECTED_VARIANT.cv_name_inch
            );

            let selectedCanvasVariant = variants.filter(
                (variant) => variant.cv_style === SELECTED_CARDS_STYLE_TO_EDIT
            )[0];
            commit("setSelectedVariant", selectedCanvasVariant);
        },

        getSelectedVariant({ commit }, variants) {
            if (
                this.getters.currentDesign?.client_product
                    ?.client_product_type_id > 5
            ) {
                if(variants[0].cv_pages){
                    //assign first page of calendar
                    commit("setSelectedVariant", variants[0].cv_pages[0]);
                }
                
            } else {
                //get first active variant
                let variant = helpers.getFirstActive(variants);
                
                //set first active variant
                commit("setSelectedVariant", variant);
            }
        },
    },

    getters: {
        selectedVariant: (state) => state.selectedVariant,
    },
};

export default selectedVariant;
