import { createRouter, createWebHistory } from "vue-router";
import checkAuth from "../middlewares/checkAuth";
import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    checkAuth(to, next)
});

export default router;
