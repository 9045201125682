const changeImageLinkModal = {
    state: {
        imageLinkModal: {
            active: false,
            data: null
        },
    },
    mutations: {
        setImageLinkModal: (state, [popupState, data]) => (state.imageLinkModal = {active: popupState, data: data}),
    },
    actions: {
        setImageLinkModal({ commit }, [popupState, data]) {
            commit("setImageLinkModal", [popupState, data]);
        },
    },

    getters: {
        imageLinkModal: (state) => state.imageLinkModal,
    },
};

export default changeImageLinkModal;
