import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

var helpers = require("@/common/helpers/variants.js");
import variantHelpers from "@/common/helpers/saveVariants.js"

const variantList = {
    state: {
        variantList: null,
        filteredCanvasList: null,
        filteredCardsList: null,
        calendarList: null,
        canvasWrap: null,
    },

    mutations: {
        setVariantList: (state, variants) => (state.variantList = variants),
        setCalendarVariantList: (state, variants) => (state.calendarList = variants),
        setFilteredCanvasList: (state, variants) => state.filteredCanvasList = variants,
        setFilteredCardsList: (state, variants) => state.filteredCardsList = variants,
        updatePhotoCalendarList: (state, variants) => state.calendarList = variants
    },

    actions: {
        setVariantList({ commit }, data) {

            if(this.getters.currentDesign?.client_product?.client_product_type_id < 6){

                let design = this.getters.currentDesign?.client_design 
                let variants = helpers.saveVariantsWithQuality([data, design]);

                let selectedWrap = this.getters.selectedWrapToEdit
                let selectedCardType = this.getters.selectedCardType
                //set aside variants that are with selected wrap

                let filteredVariants = variants.filter((variant) => variant.cv_attribute_value === selectedWrap)
                let filteredCardsVariants = variants.filter((variant) => variant.cv_style === selectedCardType)

                commit("setVariantList", variants);
                
                commit("setFilteredCanvasList", filteredVariants);
                commit("setFilteredCardsList", filteredCardsVariants);

            }else {

                let width = this.getters.currentDesign?.client_product?.client_variants?.cv_width
                let height = this.getters.currentDesign?.client_product?.client_variants?.cv_height
                
                

                let calendarVariants = helpers.saveCalendarVariantsWithQuality([data.cv_pages, width, height]);
                
                commit("setCalendarVariantList", calendarVariants);
            }

        },

        updateVariantList({ dispatch, state }, editedVariant) {
            let variants = state.variantList;
            variants.forEach((variant) => {
                if (variant.cv_id === editedVariant.cv_id) {
                    variant.cv_start_x = editedVariant.cv_start_x;
                    variant.cv_start_y = editedVariant.cv_start_y;
                    variant.cv_canvas_width = editedVariant.cv_canvas_width;
                    variant.cv_canvas_height = editedVariant.cv_canvas_height;
                    variant.cv_crop_w = editedVariant.cv_crop_w;
                    variant.cv_crop_h = editedVariant.cv_crop_h;
                    variant.cv_rotate = editedVariant.cv_rotate;
                    variant.cv_scale_x = editedVariant.cv_scale_x;
                    variant.cv_scale_y = editedVariant.cv_scale_y;
                    variant.cv_data = editedVariant.cv_data;
                }
            });
            dispatch("setVariantList", variants);
        },

        updatePhotoCalendarList({commit}, variants){
            commit('updatePhotoCalendarList', variants)
        },

        changeVariantActiveState({ commit, state }, [variantId, variantState]) {
            let variants = state.variantList;
            variants.forEach((variant) => {
                if (variant.cv_id === variantId) {
                    variant.cv_active = variantState;
                }
            });
            commit("setVariantList", variants);
        },

        changeVariantCosts(
            { commit, state },
            [variantId, newMarkup, newRetailPrice]
        ) {
            let variants = state.variantList;
            variants.forEach((variant) => {
                if (variant.cv_id === variantId) {
                    variant.cv_markup = newMarkup;
                    variant.cv_retail_price = newRetailPrice;
                }
            });
            commit("setVariantList", variants);
        },

        setValuesToCanvasVariants({commit, state}){
            let variantList = state.variantList
            //filter mirror variants
            let mirrorVariants = variantList.filter(variant => variant.cv_attribute_value === "mirror")
            //for each mirror variant find pixel extend variant and duplicate values
                mirrorVariants.forEach(variant => {
                    let index = variantList.findIndex(item => (item.cv_name_cm === variant.cv_name_cm && item.cv_attribute_value === "pixel_extend"))

                    if(index > -1){
                        variantList[index].cv_start_x = variant.cv_start_x,
                        variantList[index].cv_start_y = variant.cv_start_y,
                        variantList[index].cv_canvas_width = variant.cv_canvas_width,
                        variantList[index].cv_canvas_height = variant.cv_canvas_height,
                        variantList[index].cv_crop_w = variant.cv_crop_w,
                        variantList[index].cv_crop_h = variant.cv_crop_h,
                        variantList[index].cv_rotate = variant.cv_rotate,
                        variantList[index].cv_scale_x = variant.cv_scale_x,
                        variantList[index].cv_scale_y = variant.cv_scale_y,
                        variantList[index].cv_retail_price = variant.cv_retail_price,
                        variantList[index].cv_markup = variant.cv_markup,
                        variantList[index].cv_data = variant.cv_data
                    }

                    
                })
            commit('setVariantList', variantList)
        },

        saveProductVariants({ dispatch}) {
            dispatch("setSpecialLoading", "saveVariants");
        
           let variantData = variantHelpers.saveVariants(this.getters)


            return new Promise((resolve, reject) => {
                axios
                    .patch(
                        baseUrl + `/api/client/client-variants/update-variants`,
                        variantData
                    )
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Changes successfully saved!",
                            timeout: 3000,
                        });
                        dispatch("resetSpecialLoading");
                        //publish product if it was already published to save new changes
                        let published = this.getters.currentDesign.client_product.client_product_is_published
                        if (published) {
                            let selectedDesign = this.getters.currentDesign.client_design.cd_id
                                .cd_id;

                            dispatch("publishProduct", [
                                selectedDesign,
                                [variantData.product.cp_id],
                            ]);
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been problem saving changes!",
                            timeout: 4000,
                        });

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        resetLists({commit}){
            commit('setVariantList', null)
            commit('setCalendarVariantList', null)
            commit('setFilteredCanvasList', null)
            commit('setFilteredCardsList', null)
        }
    },

    getters: {
        variantList: (state) => state.variantList,
        canvasList: (state) => state.filteredCanvasList,
        cardsList: state => state.filteredCardsList,
        calendarList: state => state.calendarList
    },
};

export default variantList;
