<template>
    <div class="flex-1 flex flex-col justify-center items-center text-center">
        <div
            v-if="!paymentInProgress"
            @click="goTonextView()"
            class="w-36 rounded-md flex flex-col justify-center items-center hover:shadow-md cursor-pointer hover:bg-gray-50 text-gray-600 hover:text-accent"
        >
            <svg
                class="fill-current w-16 h-16 pointer-events-none"
                viewBox="0 0 16 15"
            >
                <path
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                />
            </svg>
            <span
                class="text-sm font-medium pb-1 pointer-events-none select-none"
            >
                Credit card
            </span>
            
        </div>

        <div>
            <div>
            <svg
                v-if="!sessionId && paymentInProgress"
                class="animate-spin h-6"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30px"
                height="211px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style="margin-right:-2px;display:block;background-repeat-y:initial;background-repeat-x:initial"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="32"
                    stroke-width="8"
                    stroke="#000000"
                    stroke-dasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    stroke-linecap="round"
                ></circle>
            </svg>
        </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

import { loadStripe } from '@stripe/stripe-js';

import { useStore } from "vuex";
var helpers = require("@/common/helpers/filterItemIds.js");

export default {
    name: "paymentMethods",
    setup() {
        const store = useStore();
        const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        const mounted = ref(false);
        const paymentInProgress = ref(false);
        const checkoutRef = ref(null);

        const quedItemsToPay = computed(() => store.getters.quedItemsToPay);
        const orderToPay = computed(() => store.getters.orderToPay);
        const invoiceToPay = computed(() => store.getters.invoiceToPay);
        const sessionId = computed(() => store.getters.sessionId);

        onMounted(() => {
            mounted.value = true;
        });

        const goTonextView = () => {

            let payload
            let paymentType = "orders"

            if (orderToPay.value) {
                payload = {
                    orders: [orderToPay.value]
                }
            }

            if (invoiceToPay.value) {
                paymentType = "invoices"
                payload = {
                    invoices: [invoiceToPay.value]
                }
            }

            if (quedItemsToPay.value && quedItemsToPay.value[0]?.invoice_id) {
                paymentType = "invoices"
                payload = helpers.getItemIds(quedItemsToPay)
            }

            if (quedItemsToPay.value && quedItemsToPay.value[0]?.order_id) {
                payload = helpers.getItemIds(quedItemsToPay.value)
            }

            paymentInProgress.value = true;


            store.dispatch("setPayment", [paymentType, payload]).then(() => {
                store.dispatch('removeOrderToPay')
                store.dispatch('removeInvoiceToPay')
                submit();
                paymentInProgress.value = false;

            })


        };

        const submit = async () => {
            const stripe = await stripePromise;
            try {
                stripe.redirectToCheckout({ sessionId: sessionId.value });
            } catch (error) {
                console.error(error);
            }
        };

        return {
            mounted,
            paymentInProgress,
            checkoutRef,
            quedItemsToPay,
            orderToPay,
            invoiceToPay,
            sessionId,
            goTonextView,
            submit
        };
    }
};
</script>