<template>
    <Listbox disabled="" @update:model-value="handleInput" as="div" v-model="inputValue">
        <div class="relative">
            <ListboxButton
                class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent sm:text-sm hover:border hover:border-black cursor-pointer"
                :class="inputValue?.oi_thumbnail_url ? 'pl-3' : ''"
            >
                <span class="flex items-center">
                    <img
                        v-if="inputValue?.oi_thumbnail_url"
                        :src="inputValue.oi_thumbnail_url"
                        alt=""
                        class="flex-shrink-0 h-6 w-6 rounded"
                    />
                    <span class="ml-3 block truncate">{{
                        !inputValue?.oi_thumbnail_url
                            ? `${inputValue.oi_product}`
                            : ` ${inputValue.oi_product} | ${inputValue.oi_cv_sku_nr} | ${inputValue.oi_sku_item}`
                    }}</span>
                </span>
                <span
                    class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        as="template"
                        v-for="returnItem in products"
                        :key="returnItem.oi_id"
                        :value="returnItem"
                        v-slot="{ active, selected }"
                    >
                        <li
                            :class="[
                                active
                                    ? 'text-white bg-accent'
                                    : 'text-gray-900',
                                'cursor-default select-none relative py-2  pr-9',
                                returnItem?.oi_thumbnail_url && 'pl-3',
                            ]"
                        >
                            <div class="flex items-center">
                                <img
                                    v-if="returnItem?.oi_thumbnail_url"
                                    :src="returnItem.oi_thumbnail_url"
                                    alt=""
                                    class="flex-shrink-0 h-6 w-6 rounded"
                                />
                                <span
                                    :class="[
                                        selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                        'ml-3 block truncate',
                                    ]"
                                >
                                    {{
                                        !returnItem?.oi_thumbnail_url
                                            ? `${returnItem.oi_product}`
                                            : ` ${returnItem.oi_product} | ${returnItem.oi_cv_sku_nr} | ${returnItem.oi_sku_item}`
                                    }}
                                </span>
                            </div>

                            <span
                                v-if="selected"
                                :class="[
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                            >
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
export default {

    created() {
        this.inputValue = this.modelValue;
    },
    emits: ["update:modelValue"],

    methods:{
        handleInput(e) {
            this.$emit("update:modelValue", e);
        },
    },

    props: {
        modelValue: [Object, String],
        products: [Array]
    },

    data(){
        return{
            inputValue: null
        }
    },
    components: { Listbox, ListboxButton, ListboxOption, ListboxOptions },

    name: "refundItemSelect",
};
</script>

<style scoped>

</style>
