<template>
    <div class="flex flex-col justify-center pt-2 pb-0 w-full">
        <div class="flex justify-center pb-0  ">
            <div
                class="flex flex-col space-y-5 w-11/12 xl:w-7/12 lg:w-9/12  md:w-10/12 "
            >
                <span class="text-md text-green-500" style="height:10px">
                    {{ success.active ? success.message : "" }}
                </span>
                <input
                    ref="email"
                    class="rounded-sm px-4 py-2 mt-3 focus:outline-none bg-gray-100 w-full"
                    type="text"
                    name="email"
                    v-model="input.email"
                    placeholder="Enter your registered email address"
                />
            </div>
        </div>
        <div class="flex justify-center " style="height:10px">
            <div
                class="duration-500 flex justify-auto w-11/12 xl:w-7/12 lg:w-9/12  md:w-10/12 "
            >
                <span class="text-sm text-red-600">
                    {{ error }}{{ authErrorMessage }}
                </span>
            </div>
        </div>

        <div class="flex flex-col items-center pt-12">
            <button
                class="block text-center text-white bg-accent p-3 duration-500 rounded-sm hover:bg-black w-52 focus:outline-none"
                type="submit"
                @click="resetPassword()"
            >
                Send
            </button>
            <span
                @click="goToLogin()"
                class=" uppercase text-xs py-2 tracking-wider text-accent cursor-pointer hover:text-black"
                >login</span
            >
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "forgotPasswordForm",

    data() {
        return {
            input: {
                email: "",
            },
            error: null,
            success: {
                active: false,
                message: "Check your email!",
            },
        };
    },
    computed: {
        ...mapGetters(["authErrorMessage"]),
    },
    methods: {
        resetPassword() {
            this.success.active = false;
            this.$store.dispatch("clearErrors");
            this.checkForm();
            if (!this.error) {
                var email = { email: this.input.email };
                this.$store
                    .dispatch("sendResetPasswordLink", email)
                    .then(() => {
                        this.success.active = true;
                    });
                this.input = {
                    email: "",
                };
            }
        },

        checkForm: function() {
            this.error = "";

            if (!this.validEmail(this.input.email)) {
                this.error = "Please enter valid email address.";
            }

            if (!this.error) {
                this.error = null;
                return true;
            }
        },

        validEmail: function(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        goToLogin() {
            this.$store.dispatch("setLoginForm", "login-form");
        },
    },
};
</script>

<style scoped></style>
