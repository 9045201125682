const termsAndConditions = {
    state: {
        termsPopup: false,
    },

    mutations: {
        setTermsPopup: (state, status) => (state.termsPopup = status),
    },

    actions: {
        setTermsPopup({ commit }, status) {
            commit("setTermsPopup", status);
        },
    },

    getters:{
        termsPopup: (state) => state.termsPopup,
    }
};

export default termsAndConditions;
