<template>
    <div
        v-if="basicPopup.active"
        @click.self="cancel()"
        class="absolute inset-0 bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center"
    >
        <div class="bg-white w-96 h-64 flex flex-col rounded-3xl">
            <div
                class=" border-b text-white w-full h-1/4 rounded-t-3xl flex justify-center items-center"
            >
                <span class="text-accent font-medium">Just checking..</span>
            </div>
            <div
                v-if="!basicPopup?.data?.fields"
                class="flex-1 flex flex-col justify-center items-center text-center"
            >
                <span class="text-3xl text-gray-500 pb-2">Are you sure?</span>
                <span class=" text-gray-400 text-sm px-14"
                    >This action is final and you will not be able to recover
                    any data</span
                >
            </div>
            <div
                v-else
                class="flex-1 flex flex-col justify-center items-center text-center"
            >
                <span class="text-3xl text-gray-500 pb-2"
                    >{{ basicPopup.data.fields.header }}?</span
                >
                <span class=" text-gray-400 text-sm px-14 pb-3">{{
                    basicPopup.data.fields.body
                }}</span>
            </div>
            <div
                class=" bg-accent text-white w-full h-1/4 rounded-b-3xl flex justify-center"
            >
                <button
                    @click="submit"
                    class="text-2xl font-medium uppercase hover:bg-gray-100 hover:bg-opacity-30 px-8  focus:outline-none"
                >
                    yes
                </button>
                <button
                    @click="cancel"
                    class="text-2xl font-medium uppercase hover:bg-gray-100 hover:bg-opacity-30 px-8  focus:outline-none"
                >
                    no
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["basicPopup"]),
    },
    methods: {
        submit() {
            this.emitter.emit("basicDialogPositive", this.basicPopup.data);
            this.$store.dispatch("setBasicPopup", [false]);
        },
        cancel() {
            this.$store.dispatch("setBasicPopup", [false]);
        },
    },
    name: "basicPopup",
};
</script>

<style></style>
