import updateVariant from './updateVariant';
import setCurrentDesign from './setCurrentDesign';
import selectedVariant from './selectedVariant';
import variantList from './variantList';
import qualityCheck from './qualityCheck';





const editor = {
    state: {
        previewDesign: false,

        editorRenderKey: 1,
        workspace: {
            width: 350,
            height: 400,
        },
        editorRatio: 1,
        editorData: null,

        activeChanges: false
    },

    mutations: {
        showDesignPreview(state, viewState){ state.previewDesign = viewState},

        setEditorRenderKey(state, key) {
            state.editorRenderKey = key;
        },

        setWorkspace(state, [width, height]) {
            state.workspace.width = width;
            state.workspace.height = height;
        },

        setEditorData(state, data) {
            state.editorData = data;
        },

        setEditorRatio(state, data) {
            state.editorRatio = data;
        },
        setActiveChanges: state=> state.activeChanges = true,
        reSetActiveChanges: state=> state.activeChanges = false,
    },

    actions: {
        showDesignPreview({commit}, previewState){ //true, false
            commit("showDesignPreview", previewState)
        },
        setEditorRenderKey({ commit, state }) {
            let key = state.editorRenderKey + 1;
            commit("setEditorRenderKey", key);
        },

        setWorkspace({ commit }, [width, height]) {
            commit("setWorkspace", [width, height]);
        },

        setEditorRatio({ commit }, data) {
            commit("setEditorRatio", data);
        },

        setEditorData({ commit }, data) {
            commit("setEditorData", data);

        },

        setActiveChanges({commit}){
            commit('setActiveChanges')
        },

        reSetActiveChanges({commit}){
            commit('reSetActiveChanges')
        }
    },

    getters: {
        previewDesign: state => state.previewDesign,

        editorRenderKey: (state) => state.editorRenderKey,
        workspace: (state) => state.workspace,

        editorRatio: (state) => state.editorRatio,
        editorData: (state) => state.editorData,

        activeChanges: state => state.activeChanges
    },

    modules:{
        updateVariant,
        setCurrentDesign,
        selectedVariant,
        variantList,
        qualityCheck
    }
};

export default editor;
