const orderTable = {
    state: {
        activeTab: "allOrders",
        activeTableItem: null,
        orderQueryString: null,
        orderFilterString: null,
    },

    mutations: {
        setActiveTab(state, value) {
            state.activeTab = value;
        },
        setActiveTableItem(state, value) {
            state.activeTableItem = value;
        },
        setOrderQueryString: (state, string) =>
            (state.orderQueryString = string),
        setOrderFilterString: (state, string) =>
            (state.orderFilterString = string),

        resetOrderTable(state) {
            (state.activeTab = "allOrders"),
                (state.activeTableItem = null),
                (state.orderQueryString = null),
                (state.orderFilterString = null);
        },
    },

    actions: {
        setActiveTab({ commit }, value) {
            commit("setActiveTab", value);
        },

        setActiveTableItem({ commit }, value) {
            commit("setActiveTableItem", value);
        },

        setOrderQueryString({ commit }, string) {
            commit("setOrderQueryString", string);
        },

        setOrderFilterString({ commit }, string) {
            commit("setOrderFilterString", string);
        },
    },

    getters: {
        activeTab: (state) => state.activeTab,
        activeTableItem: (state) => state.activeTableItem,
        ordersFilterString: (state) => state.orderFilterString,
        ordersqueryString: (state) => state.orderQueryString,
    },
};

export default orderTable;
