

const customSizeModal = {

    state:{
        customSizeModal: false,

    },

    mutations:{
        setCustomSizeModal :(state, value) => state.customSizeModal = value
    },
    actions:{
        setCustomSizeModal({commit}, value){
            commit('setCustomSizeModal', value)
        }
    },

    getters:{
        customSizeModal: state=> state.customSizeModal
    }
}

export default customSizeModal