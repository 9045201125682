const canvas = {
    state: {
        selectedWrapToEdit: "natural_wrap",
    },

    mutations: {
        setWrapToEdit: (state, wrap) => (state.selectedWrapToEdit = wrap),
    },

    actions: {
        setWrapToEdit({ commit }, wrap) {
            commit("setWrapToEdit", wrap);
        },
    },

    getters: {
        selectedWrapToEdit: (state) => state.selectedWrapToEdit,
    },
};
export default canvas;
