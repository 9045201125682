import axios from "axios";
import router from '../../../../router';


var baseUrl = process.env.VUE_APP_BASE_URL;

const designList = {
    state: {
        designsList: null,
        designsPagination: null,
    },

    mutations: {
        setDesignsList: (state, data) => {
            (state.designsList = data.data),
                (state.designsPagination = data.meta);
        },
        setOnlyDesigns: (state, data) => {
            (state.designsList = data)
        },
        resetDesignList(state){
            state.designsList = null,
            state.designsPagination = null
        },
        resetPagination(state){
            state.designsPagination = null
        },
        
    },

    actions: {
        resetDesignList({commit}){commit('resetDesignList')},

        getDesignsList({ commit, dispatch }, [pagination, page]) {
           commit('resetPagination')

            page = page ? page : 1;

            pagination =  typeof pagination === String ? 'pagination=all' : `pagination=${pagination}&page=${page}`

            let searchKeyword = this.getters.designSearchKeyword || ''
            
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/client/designs?${pagination}&search=${searchKeyword}`)
                    .then((response) => {
                        commit("setDesignsList", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        deleteDesign({ dispatch }, designId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + `/api/client/designs/${designId}`)
                    .then((response) => {
                        if (
                            this.getters.designsPagination.last_page > 1 &&
                            this.getters.designsPagination.from ===
                                this.getters.designsPagination.total
                        ) {
                            dispatch(
                                "getDesignsList",
                                [12, this.getters.designsPagination?.current_page - 1]
                            );
                        } else {
                            dispatch(
                                "getDesignsList",
                                [12, this.getters.designsPagination?.current_page]
                            );
                        }
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Design deleted successfully!",
                            timeout: 2500,
                        });

                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: "There has been a problem deleting design!",
                            timeout: 4000,
                        });
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        changeDesignName({ dispatch }, [design, designName]) {
            let isProductsView = router.currentRoute.value.name ===
                        "product designs"

            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/client/designs/${design.cd_id}`, {
                        cd_name: designName,
                    })
                    .then((response) => {
                        let page = this.getters.designsPagination?.current_page

                        if (isProductsView) {
                            dispatch("getDesignsList", [30, page]);
                        } else {
                            dispatch("getDesignsList", [12, page]);
                        }

                        dispatch("getAvailableProductList", design.cd_id);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        changeDesignOrientationOptions({ dispatch }, [design, options]) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/client/designs/${design.cd_id}`, {
                        cd_orientations: options,
                    })
                    .then((response) => {
                        dispatch(
                            "getDesignsList",
                            [12, this.getters.designsPagination?.current_page]
                        );
                        dispatch("getAvailableProductList", design.cd_id);

                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {
        designsList: (state) => state.designsList,
        designsPagination: (state) => state.designsPagination,
    },
};

export default designList;
