
var baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";

const refundAPI = {
    state: {
        refundReasonList: null,
    },

    mutations: {
        setRefundReasonList: (state, list) => (state.refundReasonList = list),
    },

    actions: {
        getReasonList({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/refunds/create`)
                    .then((response) => {
                        commit("setRefundReasonList", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        createRefund({ dispatch }, [orderID, data]) {
             dispatch("setLoading");
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + `/api/orders/${orderID}/refunds`, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: "Refund submited successfully!",
                            timeout: 2500,
                        });
                        dispatch("resetLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });
                        dispatch("resetLoading");
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {
        refundReasonList: (state) => state.refundReasonList,
    },
};

export default refundAPI;