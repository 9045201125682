<template>
    <div
        @click.self="closeDialog()"
        class="absolute inset-0 bg-gray-200 z-50 bg-opacity-50 flex justify-center items-center"
    >
        <div
            class="bg-white w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-auto  lg:h-auto flex flex-col rounded-3xl"
            style="min-height: 256px;"
        >
            <div
                class="relative text-white w-full h-16 rounded-t-3xl flex justify-center items-center"
            >   
            <span class="text-sm lg:text-lg font-semibold text-black px-10"
                    >The registration process has been completed –
                    congratulations!
                </span>
                <div
                    @click="closeDialog()"
                    class="absolute right-4 text-white w-5 h-5 bg-red-400 hover:bg-red-500 rounded-full text-center text-sm cursor-pointer"
                >
                    <span class="mdi mdi-close"></span>
                </div>
            </div>

            <div class="p-9 flex h-full flex-col text-center" >
                <div class="flex justify-center py-4">
                    <svg class="fill-current text-accent h-16 lg:h-24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.57 136.8"><path class="cls-1" d="M105.77,136.8c-28.5,0-54.41-19-66.42-49a5,5,0,0,1,.51-4.65A4.9,4.9,0,0,1,44,81l122.48,1.07a5,5,0,0,1,4.57,6.81c-11.49,28.65-35.73,47-63.27,47.87l-2,0M51.71,91c11.69,22.7,32.89,36.52,55.76,35.84,21.34-.68,40.45-13.86,51.29-34.9Z"/><path class="cls-1" d="M129.71,91a5,5,0,0,1-4.07-2.11L73.84,15.17c-5.7,15.37-14.65,49.06,7.65,66.94a5,5,0,1,1-6.21,7.74c-14.92-11.95-20.78-31-17-55a123,123,0,0,1,10-32.06,5,5,0,0,1,8.51-.64l57,81a5,5,0,0,1-4,7.82"/><path  class="cls-1" d="M63.28,119.4H33.42A5,5,0,0,1,29.91,118L1.45,89.49A5,5,0,0,1,5,81H42.78a5,5,0,1,1,0,9.92H17l18.53,18.54h27.8a5,5,0,0,1,0,9.92"/><path class="cls-1" d="M166.43,92a5,5,0,0,1-5-5v-27c0-1.78-.6-17.39-17.39-17.39-7.38,0-12.42,2.84-15.39,8.68a24.29,24.29,0,0,0-2.45,9.24V73.68a5,5,0,0,1-9.93,0V60.54a33.61,33.61,0,0,1,3.53-13.65c3.27-6.44,10.13-14.12,24.24-14.12,8,0,14.49,2.45,19.31,7.28,8,8,8,19.57,8,20.05v27a5,5,0,0,1-5,5"/><path class="cls-1" d="M166.43,81.91a4.95,4.95,0,0,1-5-5V59.44a5,5,0,0,1,7.45-4.3l15.16,8.75a5,5,0,0,1,0,8.6l-15.16,8.76a5,5,0,0,1-2.49.66m5-13.88v.32l.28-.16Z"/></svg>
                </div>
                
                <span class="flex text-sm lg:text-lg pt-6 max-w-3xl whitespace-pre-line">
                    Now you can log in and start designing your own products :) <br> One of our
                    product managers will get in touch soon to explain the
                    integration and partnership process. We're excited to see what you'll create!
                </span>

                <div class="flex h-full items-end justify-center text-sm md:text-md">
                    <span class="pt-6">
                    P.S. You can always reach us through <b class="text-accent">yes@printpigeons.com</b>
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["termsPopup"]),
    },

    methods: {
        closeDialog() {
            this.$router.replace({name:'products'})
        },
    },
    name: "welcomePopup",
};
</script>

<style></style>
