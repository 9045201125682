
import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const basketDelivery = {


    state:{
        cartPrices: null,
        cartInformation: null
    },

    mutations:{
        getCartPrices: (state, prices) => state.cartPrices = prices,
        getCartInformation: (state, cartInformation) => state.cartInformation = cartInformation
        
    },

    actions:{
        setRecipientsAddress({ dispatch, commit }, body) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/cart/address", body)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });
        },

        getCartInformation({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/cart/show")
                    .then((response) => {
                        commit("getCartInformation", response.data.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        getCartPrices({ dispatch, commit }, countryId) {
            dispatch("setLoading");
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/cart/address/get-prices", {
                        params: {country_id:  countryId}
                    })
                    .then((response) => {
                        commit("getCartPrices", response.data);
                        dispatch("resetLoading");

                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("resetLoading");
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        resetCartPrice({commit}){
            commit('getCartPrices', null)
        }
    },

    getters:{
        cartPrices: state=> state.cartPrices,
        cartInformation: state => state.cartInformation
    }

}

export default basketDelivery