import router from '../../../router';


const productListNavigation = {
    state: {
        productStepID: null,
        productStepVariantID: null,
        productStepVariantAttribute: null,
    },

    mutations: {
        setProductStepID: (state, id) => (state.productStepID = id),
        setProductStepVariantID: (state, id)=> state.productStepVariantID = id,
        setProductStepVariantAttribute: (state, attrib) => state.productStepVariantAttribute = attrib
    },
    actions: {
        setProductStepID({ commit }, id) {
            commit("setProductStepID", id);
        },

        setProductStepVariantID({commit}, id){
            commit('setProductStepVariantID', id)
        },
        
        setProductStepVariantAttribute({commit}, attrib){
            commit('setProductStepVariantAttribute', attrib)
        },

        clearProductStepValues({commit}){
            commit("setProductStepID", null);
            commit('setProductStepVariantID', null)
            commit('setProductStepVariantAttribute', null)
        },
        goToDesignsStep({ state, dispatch }){

            if(!this.getters.user){
                dispatch("setLoginForm", 'guest-user-login')
                router.push({ path: "/register" });
                // dispatch("setLoginModal", true);
            }

            if(this.getters.user){
                // dispatch("setLoginModal", false);
                router.push({
                    name: "product designs",
                    params: { productID: state.productStepID, 
                              variantID: state.productStepVariantID },
                });
            } 

        }
    },

    getters: {
        productStepID: state => state.productStepID,
        productStepVariantAttribute: state => state.productStepVariantAttribute,
        productStepVariantID: state => state.productStepVariantID,
        haveProductStepIDs: state => state.productStepID !== null && state.productStepVariantID !== null
    },
};

export default productListNavigation;
