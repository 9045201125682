<template>
    <div
        v-if="orderRefundModal"
        class="absolute inset-0 max-h-screen bg-gray-200 z-50 bg-opacity-50 flex justify-center overflow-y-auto"
    >
        <div class="w-full md:w-[600px] drop-shadow-xl my-auto">
            <div class="bg-white">
                <div
                    class="relative border-b text-white w-full h-16 flex justify-center items-center"
                >
                    <span class="text-gray-800 font-medium"
                        >Refund / reprint order</span
                    >
                </div>
                <!-- content elements -->
                <template v-if="true">
                    <div
                        v-for="item in returnItemList"
                        :key="item.returnItem.oi_id"
                        class="w-full h-full p-4"
                    >
                        <div class="flex flex-col w-full">
                            <span class="py-2">*Product: </span>
                            <refund-item-select
                                @update:model-value="checkItemReturned"
                                v-model="item.returnItem"
                                :products="products"
                            />
                            <div class="flex gap-x-2 pt-4">
                                <template v-for="type in refundReasonList?.types" :key="type">
                                    <input :id="type.rt_id" :name="type.rt_id" :value="type" v-model="refundType" type="radio"/>
                                    <label  for="optionA">{{type?.rt_name}}</label>
                                </template>
                            </div>

                            <span class="py-2">*Reason of return: </span>
                            <select
                                v-model="item.reason"
                                class="select"
                                name="product"
                            >
                                <option :value="null" disabled selected hidden>
                                    select from list
                                </option>
                                <option
                                    class="text-black w-1/2 truncate"
                                    v-for="product in refundReasonList?.reasons"
                                    :key="product"
                                    :value="product"
                                >
                                    {{ product.rr_name }}
                                </option>
                            </select>
                            <div class="border-b pb-3">
                                <textarea
                                    v-model="item.description"
                                    rows="3"
                                    placeholder="additional notes"
                                    class="text-box"
                                />
                                <div
                                    v-if="item.reason?.rr_id === 1"
                                    class="w-full flex gap-x-2"
                                >
                                    <label
                                        v-for="(image, index) in item.photos"
                                        :key="image"
                                        :for="
                                            `imageUpload` +
                                            item.returnItem.oi_id +
                                            index
                                        "
                                        class="h-20 w-20 relative"
                                    >
                                        <div
                                            v-if="!image"
                                            class="z-[5] absolute inset-0 bg-gray-200 flex items-center justify-center text-white text-5xl hover:bg-gray-300 transition-all ease-in-out cursor-pointer mdi mdi-plus"
                                        />
                                        <button
                                            @click="deleteImage(item, index)"
                                            type="button"
                                            class="mdi mdi-close-box z-[2] text-lg font-bold text-red-600 absolute -top-1 right-0"
                                        ></button>
                                        <div
                                            v-if="image"
                                            class="bg-white w-3 h-3 top-1 right-1 z-[1] absolute"
                                        ></div>
                                        <div
                                            v-if="image"
                                            :style="`background-image: url(${image})`"
                                            class="absolute inset-0 h-20 w-20 bg-cover bg-center"
                                        />
                                        <input
                                            @change="
                                                onFileSelected([
                                                    item,
                                                    index,
                                                    $event,
                                                ])
                                            "
                                            :name="
                                                `imageUpload` +
                                                item.returnItem.oi_id +
                                                index
                                            "
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            :id="
                                                `imageUpload` +
                                                item.returnItem.oi_id +
                                                index
                                            "
                                        />
                                    </label>
                                </div>
                            </div>
                            <!-- <button
                                v-if="index === returnItemList.length-1 && returnItemList[0].returnItem.oi_id !== 0 && returnItemList.length>1 "
                                type="button"
                                class="bg-accent flex text-white rounded-full max-w-min px-2 pr-3 gap-x-1 my-2 transition-all ease-in-out hover:bg-gray-800 hover:shadow-md"
                            >
                                <span class="mdi mdi-plus-circle"></span>
                                item
                            </button> -->
                        </div>
                    </div>
                </template>
                <!-- dialog footer -->
                <div class="w-full h-16 flex gap-x-2 justify-center">
                    <button
                        @click="closeDialog()"
                        class="text-lg h-10 border border-gray-800 text-gray-800 transition-all ease-in-out hover:bg-gray-800 font-medium capitalize px-8 focus:outline-none select-none hover:text-white"
                    >
                        cancel
                    </button>
                    <button
                        @click="submitForm()"
                        class="text-lg h-10 border font-medium capitalize text-white bg-accent transition-all ease-in-out hover:bg-gray-800 px-8 focus:outline-none select-none"
                    >
                        <span v-if="!isLoading">submit</span>
                        <svg
                            v-if="isLoading"
                            class="animate-spin h-10"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="30px"
                            height="30px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                            style="
                                margin-right: -2px;
                                display: block;
                                background-repeat-y: initial;
                                background-repeat-x: initial;
                            "
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="32"
                                stroke-width="9"
                                stroke="#ffff"
                                stroke-dasharray="50.26548245743669 50.26548245743669"
                                fill="none"
                                stroke-linecap="round"
                            ></circle>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import refundItemSelect from "./refundItemSelect.vue";

export default {
    created() {
        this.$store.dispatch("getReasonList").then(()=>{
            this.refundType = this.refundReasonList.types[0]
        })

        this.products = Object.assign(
            this.products,
            this.orderToView.order_items
        );
        if (this.orderToView?.order_items?.length > 1) {
            this.products.unshift({
                oi_id: 0,
                oi_product: "Return all order items",
            });
        }

        if (
            this.returnItemList.length === this.orderToView?.order_items?.length
        ) {
            let list = [
                {
                    returnItem: {
                        oi_id: 0,
                        oi_product: "Return all order items",
                    },
                    reason: null,
                    description: null,
                    photos: [null, null, null, null, null],
                    files: [],
                },
            ];
            this.$store.dispatch("setReturnItemList", list);
        }
    },

    computed: {
        ...mapGetters([
            "orderRefundModal",
            "returnItemList",
            "orderToView",
            "refundReasonList",
            "isLoading",
        ]),
    },

    methods: {
        closeDialog() {
            this.$store.dispatch("setOrderRefundModal", false);
        },

        checkItemReturned(e) {
            if (e.oi_id === 0) {
                let list = [
                    {
                        returnItem: {
                            oi_id: 0,
                            oi_product: "Return all order items",
                        },
                        reason: null,
                        description: null,
                        photos: [null, null, null, null, null],
                        files: [],
                    },
                ];
                this.$store.dispatch("setReturnItemList", list);
            }
        },

        onFileSelected([item, index, e]) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage([item, index, files[0]]);
        },

        createImage([item, i, file]) {
            let reader = new FileReader();

            reader.onload = (e) => {
                item.photos[i] = e.target.result;
                item.files[i] = file;
                this.sortPhotos(item);
            };

            reader.readAsDataURL(file);
        },
        deleteImage(item, i) {
            item.photos[i] = null;
            item.files.splice(i, 1);
            this.sortPhotos(item);
        },

        sortPhotos(item) {
            item.photos.sort();
        },

        submitForm() {
            let refund_items = [],
                orderID = this.orderToView.order_id,
                formData = new FormData();

            refund_items = this.returnItemList.map((item) => {
                return {
                    ri_oi_id: item.returnItem.oi_id,
                    ri_reason_id: item.reason?.rr_id,
                    ri_description: item.description,
                    files: item.files,
                };
            });
            
            if (this.returnItemList[0].returnItem.oi_id === 0) {

                let allUnrefundedItems = this.orderToView.order_items
                    .filter((item) => item.oi_status.status_id !== 6)
                    .map((item) => {
                        return {
                            ri_oi_id: item.oi_id,
                            ri_reason_id: this.returnItemList[0].reason?.rr_id,
                            ri_description: this.returnItemList[0].description,
                            files: this.returnItemList[0].files,
                        };
                    });
                refund_items = allUnrefundedItems
            }

            refund_items.forEach((item, index) => {
                for (const key of Object.keys(item)) {
                    if (key === "files" && item["files"].length > 0) {
                        let files = item[key];

                        files.forEach((file) => {
                            formData.append(
                                `refund_items[${index}][${key}][]`,
                                file
                            );
                        });
                    }

                    if (key !== "files") {
                        formData.append(
                            `refund_items[${index}][${key}]`,
                            item[key]
                        );
                    }
                }
            });

            formData.append(`refund_type_id`, this.refundType.rt_id);

            this.$store
                .dispatch("createRefund", [orderID, formData])
                .then(() => {
                    this.closeDialog();
                });
        },
    },

    data() {
        return {
            products: [],
            selected: { oi_product: "select product" },
            refundType: null
        };
    },

    name: "orderRefundModal",
    components: { refundItemSelect },
};
</script>

<style scoped>
.text-box {
    @apply form-textarea appearance-none block w-full my-3 px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white  placeholder-slate-400 outline-none border border-gray-300 hover:border hover:border-black focus:ring-accent focus:border-accent;
}
.select {
    @apply form-select appearance-none block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0  focus:text-gray-700 focus:ring-accent focus:border-accent focus:bg-white  placeholder-slate-400 outline-none border border-gray-300 hover:border hover:border-black   cursor-pointer;
}
</style>
