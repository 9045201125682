exports.sortVariants = (variants, type) => {
    let sortedVariants = { landscape: null, portrait: null, square: null };

    var filterLandscape = function(variant) {
        return +variant.cv_height < +variant.cv_width;
    };
    var filterPortrait = function(variant) {
        return +variant.cv_height > +variant.cv_width;
    };

    var filterSquare = function(variant) {
        return +variant.cv_height === +variant.cv_width;
    };

    sortedVariants.landscape = variants.filter(filterLandscape);
    sortedVariants.portrait = variants.filter(filterPortrait);
    sortedVariants.square = variants.filter(filterSquare);

    return sortedVariants[type];
};

exports.returnProductProporty = (productId, proporties) => {
    var result;
    switch (productId) {
        case 1:
            result = proporties.framedPosters;
            break;
        case 3:
            result = proporties.canvas;
            break;
        case 5:
            result = proporties.hangerPoster;
            break;     
        default:
            result = null;
            break;
    }

    return result;
};
