
import basketContent from './basket-step/store/basketContent';
import basketDelivery from './delivery-step/basketDelivery'

import basketSummary from './summary-step/basketSummary';

import axios from "axios";
import basketHeader from './basketHeader/basketHeader';
import addCustomProductToCart from '../productList/store/addCustomProductToCart';


var baseUrl = process.env.VUE_APP_BASE_URL;


const basket = {
    modules: {
        basketHeader,
        basketContent,
        basketDelivery,
        basketSummary,
        addCustomProductToCart
    },

    actions:{
        createOrderFromBasket({ dispatch}){
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/cart/create-order`)
                    .then((response) => {
                        dispatch("clearCart")
                        dispatch("getUser")
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: response.data.message,
                            timeout: 3500,
                        });
                        
                        resolve(response);
                    })
                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        }
    }
};

export default basket;
