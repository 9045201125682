import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const variantList = {
    actions: {
        updateVariant({ dispatch }, [variantId, variant]) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + `/api/client/client-variants/${variantId}`, variant)
                    .then((response) => {
                        dispatch(
                            "setCurrentDesign",
                            this.getters.currentDesign.client_product.client_product_id
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        reject(error);
                    });
            });
        },

        
    },
};

export default variantList;
