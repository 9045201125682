

const deskCalendar ={



    actions:{
        changeDeskCalendarPrices(
            { commit },
            [ newMarkup, newRetailPrice]
        ) {
            let design = this.getters.currentDesign;

            if (design) {

                design.client_product.client_variants.cv_markup = newMarkup;


                design.client_product.client_variants.cv_retail_price = newRetailPrice;
            }

            commit("setCurrentDesign", design);
        },
    }
}

export default deskCalendar