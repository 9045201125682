const basketHeader = {
    state: {
        activeBasketStep: "basket",
    },

    mutations: {
        setActiveBasketStep(state, step) {
            state.activeBasketStep = step;
        },
    },

    actions: {
        setActiveBasketStep({ commit }, step) {
            commit("setActiveBasketStep", step);
        },
    },

    getters: {
        activeBasketStep: (state) => state.activeBasketStep,
    },
};

export default basketHeader;
