<template>
    <div class="flex justify-center pb-0 pt-4 w-full">
        <form
            class="flex flex-col space-y-5 w-11/12 xl:w-6/12 lg:w-7/12  md:w-5/12 "
        >
            <div class=" flex justify-start items-center">
                <input
                    value="2"
                    id="itemCheckbox"
                    v-model="haveStore"
                    :checked="haveStore"
                    @click.stop=""
                    @input="itemChecked"
                    ref="checkbox"
                    type="checkbox"
                    class="w-4 h-4 mr-2"
                />
                <span class="pr-5">
                    I have an online store
                </span>
            </div>
            <input
                v-if="haveStore"
                ref="storeName"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="email"
                v-model="input.storeName"
                placeholder="Name of your online store"
            />

            <input
                v-if="haveStore"
                ref="storeAddress"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="email"
                v-model="input.storeAddress"
                placeholder="Address of your online store"
            />

            <input
                ref="name"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="email"
                v-model="input.name"
                placeholder="Name"
            />

            <input
                ref="email"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="text"
                name="email"
                v-model="input.email"
                placeholder="E-mail"
            />

            <input
                ref="password"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="password"
                name="password"
                v-model="input.password"
                placeholder="Password"
            />

            <input
                ref="passwordRepeat"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full"
                type="password"
                name="password"
                v-model="input.passwordRepeat"
                placeholder="Repeat password"
            />

            <select
                v-if="availableCountryList"
                ref="selectCountry"
                class="rounded-sm px-4 py-2 mt-3 focus:ring-0 focus:border-transparent focus:outline-none border-transparent bg-gray-100 w-full appearance-none"
                name="country"
                v-model="input.country"
                :class="input.country === '' ? 'text-gray-400' : ''"
            >
                <option value="" selected disabled hidden
                    >Select country</option
                >
                <option
                    class="text-black"
                    v-for="country in availableCountryList.countries"
                    :key="country.country_id"
                    :value="country"
                    >{{ country.country_name }}</option
                >
            </select>
            <span>
                By clicking Create Account, you agree to our
                <span
                    class="text-accent cursor-pointer"
                    @click="showTermsPopup()"
                    >terms</span
                >.
            </span>
        </form>
    </div>
    <div
        class="flex justify-center w-11/12 xl:w-6/12 lg:w-7/12  md:w-5/12 mx-auto"
        style=""
    >
        <div
            class="duration-500 flex w-full "
        >
            <div v-if="authErrorMessage || errors" class="text-red-600 flex">
                <span class="text-sm py-1">
                    {{ authErrorMessage }}
                    <template v-if="errors.length">
                        <div
                            v-for="error in errors"
                            :key="error"
                            class="flex text-xs"
                        >
                            <span class="mdi mdi-circle-small" />{{ error }}
                        </div>
                    </template>
                </span>
            </div>
        </div>
    </div>
    <div class="flex justify-center pt-5">
        <button
            class="block text-center text-white bg-accent p-3 duration-500 rounded-sm hover:bg-black w-52 focus:outline-none"
            type="submit"
            @click="register()"
        >
            Create Account
        </button>
    </div>
    <div class="uppercase text-xs py-2 tracking-wider text-accent mx-auto">
        <span @click="goToLogin()" class="cursor-pointer hover:text-black"
            >login</span
        >
    </div>

    <!-- pay popup -->
    <teleport to="#modals">
        <terms-and-conditions v-if="termsPopup" />
    </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import TermsAndConditions from "../../termsAndConditionsPopup/termsAndConditions.vue";
export default {
    components: {  TermsAndConditions },
    name: "registerForm",

    created() {
        this.$store.dispatch("getCountryList");
    },

    data() {
        return {
            input: {
                name: "",
                email: "",
                password: "",
                passwordRepeat: "",
                country: "",
                storeName: null,
                storeAddress: null,
            },
            haveStore: false,
            inchValue: false,
            cmValue: true,
            errors: null,
        };
    },
    computed: {
        ...mapGetters([
            "authErrorMessage",
            "availableCountryList",
            "termsPopup",
            "haveProductStepIDs"
        ]),

        passwordsMatch() {
            if (this.input.password !== this.input.passwordRepeat) return false;
            return true;
        },
    },
    methods: {
        register() {
            this.checkForm();

            if (!this.errors) {
                var newUser = {
                    country_id: this.input.country.country_id,
                    name: this.input.name,
                    email: this.input.email,
                    password: this.input.password,

                    store: {
                        store_name: this.input.storeName,
                        store_url: this.input.storeAddress,
                    },
                };
                this.$store.dispatch("registerUser", newUser).then(() => {
                    this.$router.push({path: '/products/product-list'})
                    // if (this.$route.name === "variants" && this.haveProductStepIDs) {
                    //     this.$store.dispatch("goToDesignsStep");
                    // } else {
                    //     this.$store.dispatch("setLoginForm", "login-form");
                    //     this.$router.push({ path: "/login" });                        
                    // }
                });

                this.input = {
                    name: "",
                    email: "",
                    password: "",
                    passwordRepeat: "",
                    country: "",
                    storeName: null,
                    storeAddress: null,
                };
            }
        },

        goToLogin() {
            this.$store.dispatch("setLoginForm", "login-form");
            this.$router.push({ path: "/login" });
        },

        checkForm: function() {
            this.errors = [];

            //validate url
            const url = this.input.storeAddress;
            function isValidHttpUrl(string) {
                var res = string.match(
                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // eslint-disable-line
                );
                return res !== null;
            }

            if (this.haveStore && !isValidHttpUrl(url)) {
                this.errors.push("Enter valid store URL");
            }

            if (this.input.name === "") {
                this.errors.push("Name field is required");
            }

            if (!this.validEmail(this.input.email)) {
                this.errors.push("Valid email required.");
            }

            if (this.input.password === "") {
                this.errors.push("Password field is required");
            }

            if (
                this.input.password.length < 8 &&
                this.input.password.length > 1
            ) {
                this.errors.push("Minimum Password length is 8 characters");
            }

            if (this.input.password !== this.input.passwordRepeat) {
                this.errors.push("Passwords must match");
            }

            if (this.input.country === "") {
                this.errors.push("Country is required");
            }

            if (!this.errors.length) {
                this.errors = null;
                return true;
            }
        },

        validEmail: function(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        showTermsPopup() {
            this.$store.dispatch("setTermsPopup", true);
        },
    },
};
</script>

<style scoped></style>
