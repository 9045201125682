const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue"),
        meta: {
            protected: false,
        },
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/Register.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: {
            name: "product list",
        },
    },
    {
        path: "/",
        redirect: {
            name: "product list",
        },
    },
    {
        path: "/checkAuth/:token",
        name: "auth",
    },

    {
        path: "/products",
        redirect: {
            name: "product list",
        },
    },

    {
        path: "/designs",
        redirect: {
            name: "design list",
        },
    },

    {
        path: "/renew-password-form/:token",
        name: "Password form",
        component: () => import("../views/resetPassword.vue"),
        meta: {
            protected: false,
            layout: "AppLayoutRenewPassword",
        },
    },

    {
        path: "/news",
        name: "news",
        component: () => import("../views/about.vue"),
        meta: {
            protected: false,
            // permission: ["super-admin", "admin", "user", "guest"],
        },
    },

    {
        path: "/products",
        name: "products",
        component: () => import("../views/products/productsPage.vue"),
        meta: {
            protected: false,
        },
        children: [
            {
                path: "product-list",
                name: "product list",
                component: () => import("../views/products/productList.vue"),
                meta: {
                    protected: false,
                },
            },
            {
                path: ":productID/:designID/variants",
                name: "variants",
                component: () => import("../views/products/variants.vue"),
                meta: {
                    protected: false,
                },
            },

            {
                path: ":productID/variants/:variantID/designs",
                name: "product designs",
                component: () =>
                    import("../views/products/productsDesigns.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
            {
                path: ":productID/variants/:variantID/:designID/editor",
                name: "product editor",
                component: () => import("../views/products/productsEditor.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
            {
                path: ":productID/variants/:variantID/:designID/editor/update-product",
                name: "update product",
                component: () => import("../views/products/productsEditor.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
        ],
    },

    {
        path: "/designs",
        name: "designs",
        component: () => import("../views/designs/designsPage.vue"),
        children: [
            {
                path: "design-list",
                name: "design list",
                component: () =>
                    import("../views/designs/designsList/designsList.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user"],
                },
            },
            {
                path: ":designID/product-list",
                name: "available products",
                component: () =>
                    import("../views/designs/productsList/productsList.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user"],
                },
            },
            {
                path: ":designID/:productID/edit",
                name: "edit design",
                component: () =>
                    import("../views/designs/designsEditor/designsEditor.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user"],
                },
            },
        ],
        meta: {
            permission: ["super-admin", "admin", "user"],
        },
    },

    {
        path: "/orders",
        name: "orders",
        component: () => import("../views/orders/orders.vue"),
        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
        children: [
            {
                path: "all",
                name: "all",
                component: () => import("../views/orders/orders.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
            {
                path: "refunds",
                name: "refunds",
                component: () => import("../views/orders/orders.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
            {
                path: "paid",
                name: "paid",
                component: () => import("../views/orders/orders.vue"),
                meta: {
                    permission: ["super-admin", "admin", "user", "guest"],
                },
            },
        ],
    },

    {
        path: "/orders/:orderID/view",
        name: "view",
        component: () => import("../views/orders/viewOrder.vue"),
        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
    },

    {
        path: "/basket",
        name: "basket",
        component: () => import("../views/basket.vue"),

        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
    },

    {
        path: "/payment-success",
        name: "payment success",
        component: () => import("../views/paymentSuccesful.vue"),
        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
    },
    {
        path: "/payment-failed",
        name: "payment failed",
        component: () => import("../views/paymentFailed.vue"),
        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
    },
    {
        path: "/invoices",
        name: "invoices",
        component: () => import("../views/invoices.vue"),

        meta: {
            permission: ["super-admin", "admin", "user", "guest"],
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("../views/settings.vue"),
        meta: {
            permission: ["super-admin", "admin", "user"],
        },
    },
];

export default routes;
