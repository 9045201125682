var baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";



const availableIntegrations = {
    state: {
        availableIntegrations: null,
    },

    mutations:{
        setAvailableIntegrations: (state, list) => state.availableIntegrations = list
    },

    actions:{
        getAvailableIntegrations({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/clients/create`)
                    .then((response) => {
                        commit("setAvailableIntegrations", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },

    getters: {
        availableIntegrations: (state) => state.availableIntegrations,
    },
};

export default availableIntegrations;
