const basicPopup = {
    state: {
        basicPopup: {
            active: false,
            data: null
        },
    },
    mutations: {
        setBasicPopup: (state, [popupState, data]) => (state.basicPopup = {active: popupState, data: data}),
    },
    actions: {
        setBasicPopup({ commit }, [popupState, data]) {
            commit("setBasicPopup", [popupState, data]);
        },
    },

    getters: {
        basicPopup: (state) => state.basicPopup,
    },
};

export default basicPopup;
