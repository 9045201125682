<template>
    <transition name="fade">
    <div  v-show="options.active"  class="w-full fixed bottom-0 z-50">
        <input type="checkbox" class="hidden" id="footeralert" />

        <div
            class="flex items-center w-full p-2 shadow text-white"
            :class="options.color"
        >
            <div class="flex-1 flex justify-center">
                <svg v-if="isLoading" class="animate-spin h-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="211px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="margin-right:-2px;display:block;background-repeat-y:initial;background-repeat-x:initial" ><circle cx="50" cy="50" r="32" stroke-width="8" stroke="#fefefe" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round"  ></circle></svg>

                <span class="px-2">
                    {{options.text}}
                </span>
                <span v-if="options.resendEmail">
                    <button @click="resendEmail()" class="focus:outline-none bg-black text-white rounded-full px-2">resend</button>
                </span>
            </div>
            
            <div v-if="!isLoading" class="flex justify-end">
                <label @click="closeSnackbar()" class="cursor-pointer" title="close">
                    <svg
                        class="fill-current text-white pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                    >
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                        ></path>
                    </svg>
                </label>
            </div>
        </div>
        
    </div>
    </transition>


</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({ options: "snackbarOptions", isLoading: "isLoading" }),
    },
    methods:{
        closeSnackbar(){
            this.$store.dispatch('hideSnackbar')
        },
        resendEmail(){
            this.$store.dispatch('resendVerificationEmail')
        }

    },
    name: "snackbar",
};
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
