const navigation = {
    state: {
        homeView: '/products/product-list',
        links: [
            
            {
                name: "Products",
                protected: false,
                permission: ["super-admin", "admin", "user","guest"],
                path: "/products/product-list",
            },
            {
                name: "Designs",
                protected: true,
                permission: ["super-admin", "admin", "user"],
                path: "/designs/design-list",
            },
            { 
                name: "Orders", 
                protected: true,
                permission: ["super-admin", "admin", "user","guest"],
                path: "/orders/all" 
            },
            {
                name: "Invoices",
                protected: true,
                permission: ["super-admin", "admin", "user","guest"],
                path: "/invoices",
            },
            
            {
                name: "Settings",
                protected: true,
                permission: ["super-admin", "admin", "user"],
                path: "/settings",
            },
            {
                name: "News",
                protected: false,
                permission: ["super-admin", "admin", "user","guest"],
                path: "/news",
            }
        ],
    },


    getters:{
        links: state => state.links,
        homeView: state => state.homeView
    }
};

export default navigation;
