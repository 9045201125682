<template>
    <teleport to="#announcments">
        <announcment/>
        <snackbar />
    </teleport>
    <teleport to="#modals">
        <auth-popup />
        <change-image-link-modal />
        <basic-popup/>
        <payment-dialog/>
        <order-refund-modal v-if="orderRefundModal"/>
        <custom-size-modal/>
    </teleport>
</template>

<script>
import snackbar from "../snackbars/snackbar.vue";
import AuthPopup from "./authPopup/authPopup.vue";
import ChangeImageLinkModal from "./changeImageLinkModal/changeImageLinkModal.vue";
import BasicPopup from './basicPopup/basicPopup.vue';
import PaymentDialog from "./paymentModal/paymentDialog.vue";
import OrderRefundModal from './orderRefundModal/orderRefundModal.vue';
import { mapGetters } from 'vuex';
import CustomSizeModal from "./customSizeModal/customSizeModal.vue";
import announcment from '../snackbars/announcment.vue';

export default {
    computed:{
        ...mapGetters(["orderRefundModal"])
    },
    components: { snackbar, ChangeImageLinkModal, AuthPopup, BasicPopup, PaymentDialog, OrderRefundModal, CustomSizeModal, announcment },
    name: "modals",
};
</script>
