const importDesignFromDesigns = {



    state:{
        importMenuOpen: false
    },

    mutations:{
        openImportDesignMenu: (state) => state.importMenuOpen = true,
        closeDesignImportMenu: (state) => state.importMenuOpen = false
    },

    actions:{
        openImportDesignMenu({commit}){
            commit('openImportDesignMenu')
        },

        closeDesignImportMenu({commit}){
            commit('closeDesignImportMenu')
        },

        
    },

    getters:{
        importMenuOpen: state => state.importMenuOpen
    }
}

export default importDesignFromDesigns