const framedPosters = {

state:{
    frameColor: 'black'
},

mutations:{
    setPosterFrameColor:(state, color) => state.frameColor = color
},

actions:{
    setPosterFrameColor({commit}, color){
        commit('setPosterFrameColor', color)
    }
},


getters:{
    posterFrameColor:(state)=> state.frameColor
}

}
export default framedPosters