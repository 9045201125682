const invoicesOperations = {

    state: {
        invoices: null,
        invoiceToPay: null,
        selectedInvoices: [],
    },

    mutations: {
        setInvoices: (state, invoices) => {
            state.invoices = invoices;
        },
        setInvoicesData: (state, invoices) => {
            state.invoices.data = invoices;
        },
        setSelectedInvoice: (state, invoice) => state.selectedInvoices.push(invoice),
        setSelectedInvoices: (state, invoices) => (state.selectedInvoices = invoices),
        addInvoiceToPay: (state, invoiceId) => (state.invoiceToPay = invoiceId),
        removeInvoiceToPay: (state) => (state.invoiceToPay = null),

        resetInvoiceOperations (state){
            state.invoices = null,
            state.invoiceToPay = null,
            state.selectedInvoices = []
        }
    },

    actions: {
        addSelectedInvoice({ dispatch, commit, state }, invoiceId) {
            let selectedInvoice = null;
            let invoices = state.invoices.data;
            invoices.forEach((invoice) => {
                if (invoice.invoice_id === invoiceId) {
                    selectedInvoice = invoice;
                    invoice.checked = true;
                }
            });
            commit("setSelectedInvoice", selectedInvoice);
            dispatch('setQuedItems', state.selectedInvoices)
        },
        removeSelectedInvoice({ dispatch, commit, state }, invoiceId) {
            let invoices = state.invoices.data;
            invoices.forEach((invoice) => {
                if (invoice.invoice_id === invoiceId) {
                    invoice.checked = false;
                }
            });

            let selectedInvoices = state.selectedInvoices;
            let filtered = selectedInvoices.filter(function(value) {
                return value.invoice_id !== invoiceId;
            });
            commit("setSelectedInvoices", filtered);
            dispatch('setQuedItems', state.selectedInvoices)
        },

        addAllSelectedInvoices({ dispatch, commit, state }) {
            let invoices = state.invoices.data;
            invoices.forEach((invoice) => {
                if(!invoice.invoice_paid)
                {
                    invoice.checked = true
                    commit("setSelectedInvoice", invoice);
                }
            });
            commit("setInvoicesData", invoices);
            dispatch('setQuedItems', state.selectedInvoices)
        },
        removeAllSelectedInvoices({ dispatch, commit, state }) {
            let invoices = state.invoices.data;
            invoices.forEach((invoice) => {
                invoice.checked = false;
            });
            commit("setInvoicesData", invoices);
            commit("setSelectedInvoices", []);
            dispatch('setQuedItems', [])
        },

        //payment

        addInvoiceToPay({ commit }, invoiceId) {
            commit("addInvoiceToPay", invoiceId);
        },

        removeInvoiceToPay({ commit }) {
            commit("removeInvoiceToPay");
        },
    },

    getters: {
        userInvoices: (state) => state.invoices,
        selectedInvoices: (state) => state.selectedInvoices,
        invoiceToPay: (state) => state.invoiceToPay,
    },
};

export default invoicesOperations;
