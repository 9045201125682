import store from "../store";
import router from "../router";

async function checkAuth(to, next) {

    

    // const user = store.getters.user;
    const token = localStorage.getItem("access_token");
    const requiresPermission = to.matched.some(
        (record) => record.meta.permission
    );
    //if social login, auth token    
    if (to.name === "auth") {
        //check if address contains token
        const userToken = to.params.token || null;
        if (userToken) {//if token present save to local storage and store
            localStorage.setItem("access_token", userToken);
            store.dispatch("setToken", userToken).then(()=>{

                store.dispatch("getUser").then(()=>{ //get user
                    next({ name: "product list" }); //after that, navigate to product list
                }).catch((error)=>{//if no user, go to product view anyway
                    console.error("Failed to fetch user:", error);
                    next({ name: "product list" });
                }) 
            })
        }else {
            next()
        }
        
    }else if (requiresPermission) {
        if (!token) {
                router.replace({ name: "product list" });
                store.dispatch("setLoginForm", "login-form");
                store.dispatch("setLoginModal", true);
            } else {
                try {
                    await store.dispatch("getUser");
    
                    const userRoleName = store.getters.user.user_role.role_name;
                    if (to.meta.permission.includes(userRoleName)) {
                        next();
                    } else {
                        next({ name: "product list" });
                        store.dispatch("setLoginForm", "login-form");
                        store.dispatch("setLoginModal", true);
                    }
                } catch (error) {
                    console.error("Failed to fetch user:", error);
                    router.replace({ name: "product list" });
                    store.dispatch("setLoginForm", "login-form");
                    store.dispatch("setLoginModal", true);
                }
            }
    } else {
        //if route doesnt require premissions, check if token exists. If exists we get user and go to route
        if (token) {
            try {
                await store.dispatch("getUser");
            } catch (error) {
                console.error("Failed to fetch user:", error);
            }
        }
        next();
    }
}

export default checkAuth;
