import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const register = {
    state: {
        availableCountryList: null,
        registrationPopup: false,
        registeredEmail: null
    },

    mutations: {
        setAvailableCountryList: (state, list) => state.availableCountryList = list,
        setRegisterPopup: (state, email) => {
            state.registrationPopup = true,
                state.registeredEmail = email
        },

        resetRegistrationPopup: state => {
            state.registrationPopup = false,
                state.registeredEmail = null
        }
    },

    actions: {
        registerUser({
            commit,
            dispatch
        }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/register", payload)

                    .then((response) => {
                        commit('setRegisterPopup', payload.email)
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: 'Success! Please check your email!',
                            timeout: 4000,
                        });
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'signupSuccess',
                            'userEmail': payload?.email || null
                        });
                        resolve(response);
                    })

                    .catch((error) => {
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },


        getCountryList({
            commit,
            dispatch
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + `/api/countries-list`)
                    .then((response) => {
                        commit("setAvailableCountryList", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        resendVerificationEmail({
            dispatch,
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/email/resend")
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-green-500",
                            text: 'Success! Please check your email!',
                            timeout: 4000,
                        });
                        resolve(response);
                    })
                    .catch((error) => {

                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setErrorMessages",
                                error.response.data.errors
                            );
                        }
                        reject(error);
                    });
            });
        },

        resetRegistrationPopup({
            commit
        }) {
            commit('resetRegistrationPopup')
        }
    },

    getters: {
        availableCountryList: state => state.availableCountryList,
        registrationPopup: state => state.registrationPopup,
        registeredEmail: state => state.registratedEmail
    },
};

export default register;