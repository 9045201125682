exports.goodQualityMeasureMilimeters = ([dpi, widthMM, heightMM]) => {
    let pxMM = dpi / 25.4; //pixels on one mm

    //set dimensions of current size
    let dimensions = {
        width: widthMM * pxMM,
        height: heightMM * pxMM,
    };

    return dimensions;
};

exports.saveVariantsWithQuality = ([variants, design]) => {
    //original design resolution
    const ORIGINAL_WIDTH = design.cd_width;
    const ORIGINAL_HEIGHT = design.cd_height;
    const PIXEL_PER_MM = 3.7795275591;

    //set each variant quality depending saved data or default data
    variants.forEach((variant) => {
        //default data
        //get both sides relative ratio to frame size in px
        let widthRatio = (variant.cv_width * PIXEL_PER_MM) / ORIGINAL_WIDTH;
        let heightRatio = (variant.cv_height * PIXEL_PER_MM) / ORIGINAL_HEIGHT;
        //canvas size variable fitted to be proportianal to frame
        let canvasWidth = ORIGINAL_WIDTH * widthRatio;

        //multiply original width with ratio of shortest side
        if (widthRatio > heightRatio) {
            canvasWidth = ORIGINAL_WIDTH * widthRatio;
        }

        if (heightRatio > widthRatio) {
            canvasWidth = ORIGINAL_WIDTH * heightRatio;
        }

        //set default quality
        variant.quality = this.getQualityResult([
            variant.cv_width,
            variant.cv_height,
            ORIGINAL_WIDTH,
            canvasWidth,
            variant.cv_width * PIXEL_PER_MM,
            variant.cv_height * PIXEL_PER_MM,
        ]);

        //set quality based on saved data
        if (variant.cv_canvas_width) {
            variant.quality = this.getQualityResult([
                variant.cv_width,
                variant.cv_height,
                ORIGINAL_WIDTH,
                variant.cv_canvas_width,
                variant.cv_crop_w,
                variant.cv_crop_h,
            ]);
            return;
        }
    });

    return variants;
};

exports.saveCalendarVariantsWithQuality = ([variants, initial_width, initial_height]) => {
   

    // set each variant quality depending saved data or default data
    variants.forEach((variant) => {
         //original design resolution
        const ORIGINAL_WIDTH = variant.cvp_data?.client_design?.cd_width


        let variantWidth = initial_width
        let variantHeight =  initial_height

        variant.quality = null

        let canvasW = variant?.cvp_data?.cvp_canvas_width
        let canvasCropW = variant?.cvp_data?.cvp_crop_w 
        let canvasCropH = variant?.cvp_data?.cvp_crop_h 

        //set quality based on saved data
        if (variant?.cvp_data?.cvp_canvas_width && ORIGINAL_WIDTH) {
            variant.quality = this.getQualityResult([
                variantWidth,
                variantHeight,
                ORIGINAL_WIDTH,
                canvasW,
                canvasCropW,
                canvasCropH,
            ]);
            return;
        }
    });

    return variants;
};

exports.getQualityResult = ([
    widthMM,
    heightMM,
    originalWidth,
    canvasWidth,
    workspaceWidth,
    workspaceHeight,
]) => {
    //defined dpi
    let dpi = [
        { value: 1, name: "poor" },
        { value: 200, name: "medium" },
        { value: 290, name: "good" },
    ];

    //all dpi resolutions
    let mediumQuality = this.goodQualityMeasureMilimeters([
        dpi[1].value,
        widthMM,
        heightMM,
    ]);

    

    let goodQuality = this.goodQualityMeasureMilimeters([
        dpi[2].value,
        widthMM,
        heightMM,
    ]);

    

    //get ratio to original size
    let ratio = originalWidth / canvasWidth;

    //multiply on screen size to match as size against original size
    let cropboxWidth = workspaceWidth * ratio;
    let cropboxHeight = workspaceHeight * ratio;
    

    //check if matched size fits in one of dpi resolution zones
    if (widthMM > heightMM) {
        if (goodQuality.width < cropboxWidth) {
            return dpi[2].name;
        }

        if (
            mediumQuality.width < cropboxWidth &&
            goodQuality.width > cropboxWidth
        ) {
            return dpi[1].name;
        }

        if (mediumQuality.width > cropboxWidth) {
            return dpi[0].name;
        }
    } else {
        if (goodQuality.height < cropboxHeight) {
            return dpi[2].name;
        }

        if (
            mediumQuality.height < cropboxHeight &&
            goodQuality.height > cropboxHeight
        ) {
            return dpi[1].name;
        }

        if (mediumQuality.height > cropboxHeight) {
            return dpi[0].name;
        }
    }
    //return dpi name
    return dpi[0].name;
};

exports.getFirstActive = (data) => {
    let variantList = [];

    data.forEach((el) => variantList.push(el));

    variantList = variantList.filter(function(variant) {
        return variant.cv_active === true;
    });

    if(variantList[0]){
        variantList[0].cv_width = +variantList[0].cv_width
        variantList[0].cv_height = +variantList[0].cv_height
    }
    let variant = variantList[0] ? variantList[0] : data[0];

    return variant;
};

exports.mapVariants = (data) => {
    let variantList = [];

    data.forEach((el) => variantList.push(el));

    variantList = variantList.filter(function(variant) {
        return variant.cv_active === true;
    });
    
    let variant = variantList[0] ? variantList[0] : data[0];
    return variant;
};
