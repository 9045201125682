import editor from './editor/editor';
import configure from './configure/configure';



const productSteps = {
state:{
    currentStep: 'design',
},

mutations: {
    setCurrentProductStep: (state, name) => state.currentStep = name,
},

actions: {
    setCurrentProductStep({commit}, name){ //design, configure, information
        commit('setCurrentProductStep', name)
    },

    resetCurrentProductStep({commit}){
        commit('setCurrentProductStep', 'design')
    }
},
getters:{
    currentProductStep: state => state.currentStep
},

modules:{editor, configure}
}

export default productSteps