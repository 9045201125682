import notificationsMarkAsRead from "./notificationsMarkAsRead";
import notificationsClear from "./notificationsClear";

const notifications = {
    modules: {
        notificationsMarkAsRead,
        notificationsClear,
    },
};

export default notifications;
