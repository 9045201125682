import axios from "axios";
var baseUrl = process.env.VUE_APP_BASE_URL;

const about = {

    state:{
        aboutPosts: null,
    },

    mutations:{
        setAboutPosts:(state, posts) => state.aboutPosts = posts,
    },

    actions:{
        getAboutPosts({commit, state, dispatch}, page){
            page = page || 1 
            dispatch("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/posts/?page=" + page)
                    .then((response) => {

                        var postsData = response.data
                        var posts = response.data.data

                        if(state.aboutPosts?.data.length){
                            postsData.data = state.aboutPosts.data.concat(posts) 
                        }

                        commit('setAboutPosts', postsData)
                        dispatch("resetLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });


            // /**
            //  * Returns a random number between min (inclusive) and max (exclusive)
            //  */
            // function getRandomArbitrary(min, max) {
            //     return Math.random() * (max - min) + min;
            // }

            // /**
            //  * Returns a random integer between min (inclusive) and max (inclusive).
            //  * The value is no lower than min (or the next integer greater than min
            //  * if min isn't an integer) and no greater than max (or the next integer
            //  * lower than max if max isn't an integer).
            //  * Using Math.round() will give you a non-uniform distribution!
            //  */
            // function getRandomInt(min, max) {
            //     min = Math.ceil(min);
            //     max = Math.floor(max);
            //     return Math.floor(Math.random() * (max - min + 1)) + min;
            // }

        },

        sendFeedback({commit, dispatch}, body){
            let formData = {
                feedback_message : body.message || null,
                feedback_email : body.email || null

            }
            
            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/feedback", formData)
                    .then((response) => {
                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-accent",
                            text: "Thank you for your feedback!",
                            timeout: 2000
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch("resetLoading");
                        let messages = [];

                        for (const property in error.response.data.errors) {
                            messages.push(error.response.data.errors[property]);
                        }

                        dispatch("setSnackbar", {
                            active: true,
                            color: "bg-red-500",
                            text: messages[0][0],
                            timeout: 4000,
                        });

                        if (error.response.status === 403) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 429) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        if (error.response.status === 422) {
                            commit(
                                "setAuthErrorMessage",
                                error.response.data.message
                            );
                        }
                        reject(error);
                    });
            });

        }
    },
    getters:{
        aboutPosts:state => state.aboutPosts
    }

}

export default about