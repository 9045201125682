const cards = {
    state: {
        selectedCardType: null,
    },

    mutations: {
        setCardType: (state, type) => (state.selectedCardType = type),
    },

    actions: {
        setCardType({ commit }, type) {
            commit("setCardType", type);
        },
    },

    getters: {
        selectedCardType: (state) => state.selectedCardType,
    },
};
export default cards;
