const orderOperations = {
    state: {
        orders: null,
        orderToPay: null,
        selectedOrders: [],
    },
    mutations: {
        setOrders: (state, orders) => {
            state.orders = orders;
        },
        setOrdersData: (state, orders) => {
            state.orders.data = orders;
        },
        setSelectedOrder: (state, order) => state.selectedOrders.push(order),
        setSelectedOrders: (state, orders) => (state.selectedOrders = orders),
        addOrderToPay: (state, orderId) => (state.orderToPay = orderId),
        removeOrderToPay: (state) => (state.orderToPay = null),

        resetOrderOperations (state){
            state.orders = null,
            state.orderToPay = null,
            state.selectedOrders = []
        }
    },

    actions: {
        addSelectedOrder({ dispatch, commit, state }, orderId) {
            let selectedOrder = null;
            let orders = state.orders.data;
            orders.forEach((order) => {
                if (order.order_id === orderId) {
                    selectedOrder = order;
                    order.checked = true;
                }
            });
            commit("setSelectedOrder", selectedOrder);
            dispatch('setQuedItems', state.selectedOrders)
        },
        removeSelectedOrder({ dispatch, commit, state }, orderId) {
            let orders = state.orders.data;
            orders.forEach((order) => {
                if (order.order_id === orderId) {
                    order.checked = false;
                }
            });
            let selectedOrders = state.selectedOrders;
            let filtered = selectedOrders.filter(function(value) {
                return value.order_id !== orderId;
            });
            commit("setSelectedOrders", filtered);
            dispatch('setQuedItems', state.selectedOrders)
        },

        addAllSelectedOrders({ dispatch, commit, state }) {
            let orders = state.orders.data;
            orders.forEach((order) => {
                order.checked = true;
            });
            commit("setOrdersData", orders);
            commit("setSelectedOrders", orders);
            dispatch('setQuedItems', state.selectedOrders)
        },
        removeAllSelectedOrders({ dispatch, commit, state }) {
            let orders = state.orders.data;
            orders.forEach((order) => {
                order.checked = false;
            });
            commit("setOrdersData", orders);
            commit("setSelectedOrders", []);
            dispatch('setQuedItems', [])
        },

        //payment

        addOrderToPay({ commit }, orderId) {
            commit("addOrderToPay", orderId);
        },

        removeOrderToPay({ commit }) {
            commit("removeOrderToPay");
        },
    },

    getters: {
        userOrders: (state) => state.orders,
        selectedOrders: (state) => state.selectedOrders,
        orderToPay: (state) => state.orderToPay,
    },
};

export default orderOperations;
