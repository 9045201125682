exports.getItemIds = (items) => {
    let paymentType;
    let mappedIds = items.map((item) => {
        if (item.order_id) {
            paymentType = "orders";
            return +item.order_id;
        }

        if (item.invoice_id) {
            paymentType = "invoices";
            return +item.invoice_id;
        }

        console.error('input values not match')
    });

    let result = {};

    result[paymentType] = mappedIds;

    return result;
};
