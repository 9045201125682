import axios from "axios";
import router from "../../../router";
var baseUrl = process.env.VUE_APP_BASE_URL;

const currentDesign = {
    state: {
        currentDesign: null,
    },

    mutations: {
        setCurrentDesign(state, design) {
            state.currentDesign = design;
        },
        resetCurrentDesign: (state) => (state.currentDesign = null),
    },

    actions: {
        setCurrentDesign({ commit, dispatch, state }, productId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl +
                            `/api/client/products/${productId}/variants/create`
                    )
                    .then((response) => {
                        //update calendar design list without changing pages data
                        let isProductsView =
                            router.currentRoute.value.name === "product editor";

                        let calendarIDs = [6, 7, 8, 10];

                        if (
                            calendarIDs.includes(
                                response.data.data.client_product
                                    .client_product_type_id
                            )
                        ) {
                            if (state.currentDesign) {
                                let oldPagesData =
                                    state.currentDesign.client_product
                                        .client_variants.cv_pages;

                                let newData = response.data.data;
                                newData.client_product.client_variants.cv_pages = oldPagesData;

                                commit("setCurrentDesign", newData);
                            }
                            commit("setCurrentDesign", response.data.data);
                        } else {
                            //save variants if not calendar
                            // if name changed variants stay the same
                            let currentName =
                                state.currentDesign?.client_design.cd_name
                            let updatedName =
                                response.data.data.client_design.cd_name

                            if (
                                state.currentDesign &&
                                currentName !== updatedName
                            ) {
                                let oldVariants =
                                    state.currentDesign.client_product
                                        .client_variants;
                                let newData = response.data.data;
                                newData.client_product.client_variants = oldVariants;

                                commit("setCurrentDesign", newData);
                            }
                            commit("setCurrentDesign", response.data.data);
                        }

                        if (!isProductsView) {
                            // save variants list
                            dispatch(
                                "setVariantList",
                                response.data.data.client_product
                                    .client_variants
                            );

                            // save variant attributes of each product
                            if (
                                response.data.data.client_product
                                    .client_product_type_id === 1
                            ) {
                                dispatch(
                                    "setFrameVariants",
                                    response.data.data.client_product
                                        .client_product_attributes.frame
                                );
                            }

                            if (
                                response.data.data.client_product
                                    .client_product_type_id === 3
                            ) {
                                dispatch(
                                    "setCanvasWrapVariants",
                                    response.data.data.client_product
                                        .client_product_attributes.edges
                                );
                            }
                            if (
                                response.data.data.client_product
                                    .client_product_type_id === 5
                            ) {
                                dispatch(
                                    "setHangerVariants",
                                    response.data.data.client_product
                                        .client_product_attributes.hanger
                                );
                            }
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        resetCurrentDesign({ commit }) {
            commit("resetCurrentDesign");
        },
    },

    getters: {
        currentDesign: (state) => state.currentDesign,
    },
};

export default currentDesign;
